.FourOhfour {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FourOhfour-pageHeader {
  padding-top: 50px;
  padding-bottom: 50px;
}
