.footer-store-container {
    border-top: 3px solid var(--color-gray-lightest);
    background: white;
    padding: 1.5625rem 0;
}

.footer-store-container h3 {
    padding-bottom: .625rem;
}

.footer-store-grid-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-right: -.625rem;
    margin-left: -.625rem;
}

.footer-store-grid-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: .625rem;
    text-align: center;
    @media (--viewport-sm) {
        flex: 33.333333333%;
        max-width: 33.333333333%;
    }
    @media (--viewport-lg) {
        flex-basis: 1;
        flex-grow: 1;
        max-width: 20%;
    }
}

.footer-store-link {
    &:hover .footer-store-image {
         filter: grayscale(0%);
     }
}
.footer-store-image {
    max-width: 100%;
    border: 0;
    filter: grayscale(100%);
    transition: filter .5s;
}