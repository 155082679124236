.ProductRecipeCarousel-title {
  font-size: var(--font-size-xl);

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
    margin-bottom: 50px;
  }
}

.ProductRecipeCarousel-cell {
  display: flex;
  min-height: 100%;
  width: 80%;

  & > * {
    margin-right: 30px;
  }

  @media (--viewport-md) {
    width: calc(100% / 3);
  }
}

.ProductRecipeCarousel .flickity-prev-next-button {
  align-items: center;
  background-color: var(--color-red);
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: absolute;
  transition: background-color 200ms ease-out;
  width: 48px;

  &:hover {
    background-color: var(--color-blue);
  }

  @media (--viewport-lg) {
    left: -68px;
  }
}

.ProductRecipeCarousel .flickity-prev-next-button.next {
  top: calc(100% * (0.6875 / 2) - 53px);

  @media (--viewport-lg) {
    top: auto;
    bottom: 205px;
  }
}

.ProductRecipeCarousel .flickity-prev-next-button.previous {
  top: calc(100% * (0.6875 / 2) + 5px);

  @media (--viewport-lg) {
    top: auto;
    bottom: 143px;
  }
}

.ProductRecipeCarousel .flickity-prev-next-button[disabled] {
  background-color: var(--color-gray-light);
}

.ProductRecipeCarousel .flickity-prev-next-button svg {
  position: relative;
  left: 0;
  top: 0;
  height: auto;
  width: 24px;
}

.ProductRecipeCarousel .flickity-prev-next-button .arrow {
  fill: var(--color-white);
}
