@import 'utilities/container';

.Content-item--link_block_small,
.Content-item--link_block_large,
.Content-item--accordion,
.Content-item--carousel,
.Content-item--text,
.Content-item--quote,
.Content-item--cta_multiline,
.Content-item--divider,
.Content-item--product_recipe_carousel,
.Content-item--product_recipe_grid,
.Content-item--video {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 40px;
  }

  @media (--viewport-lg) {
    margin-bottom: 60px;
  }
}

.Content-item--subtitle_h2,
.Content-item--subtitle_h3,
.Content-item--accordion,
.Content-item--carousel,
.Content-item--cta_multiline,
.Content-item--link_block_small {
  @apply --container-base;

  max-width: var(--container-width-md);
}

.Content-item--default_image,
.Content-item--divider,
.Content-item--product_recipe_carousel,
.Content-item--product_recipe_grid {
  @apply --container-base;

  max-width: var(--container-width-lg);
}
