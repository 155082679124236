.ChapterLink {
  color: var(--color-white);
  text-decoration: none;
  width: 100%;
  display: inline-block;
  outline: none;
}

.ChapterLink:not(.ChapterLink--overImage) {
  mix-blend-mode: multiply;
}

.ChapterLink--golf_club .ChapterLink-flexEmbed::after {
  padding-bottom: 50% !important;
}

.ChapterLink--blobby .ChapterLink-flexEmbed::after {
  padding-bottom: 90.4% !important;
}

.ChapterLink--smudge .ChapterLink-flexEmbed::after {
  padding-bottom: 80% !important;
}

.ChapterLink--tilde .ChapterLink-flexEmbed::after {
  padding-bottom: 90.4% !important;
}

.ChapterLink-layout {
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.ChapterLink-shape {
  left: 50%;
  max-width: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}

.ChapterLink-title {
  color: currentColor;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  font-size: var(--font-size-xl);
  margin-bottom: 0;
  margin-top: 0;

  @media (--viewport-lg) {
    font-size: var(--font-size-xxl);
  }
}

.ChapterLink-description {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  margin-bottom: 0;
  margin-left: 20px;
  margin-top: 0;
}

.ChapterLink-button {
  background-color: var(--color-white);
  border-radius: 50%;
  margin-top: 10px;
}

.ChapterLink--blue .ChapterLink-button {
  color: var(--color-blue);
}

.ChapterLink--red .ChapterLink-button {
  color: var(--color-red);
}

.ChapterLink--yellow .ChapterLink-description {
  color: var(--color-text);
}

.ChapterLink--yellow .ChapterLink-button {
  color: var(--color-yellow);
}

.ChapterLink--purple .ChapterLink-button {
  color: var(--color-gentle-purple);
}

.ChapterLink-shapeContainer {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.ChapterLink--golf_club .ChapterLink-shape {
  transform: translate(-46%, -83%) rotateZ(17deg) scale(-1.6, 1.6);

  @media (--viewport-md) {
    transform: translate(-76%, -83%) rotateZ(-366deg) scale(1.8, 1.8);
  }
}

.ChapterLink--blobby .ChapterLink-shape {
  transform: translate(-66%, -50%) scale(1.45, 1.45);

  @media (--viewport-md) {
    transform: translate(-51%, -50%) rotate(12deg) scale(-1.4, 1.4);
  }
}

.ChapterLink--smudge .ChapterLink-shape {
  transform: translate(-50%, -60%) rotate(0deg) scale(-1.4, 1.4);

  @media (--viewport-md) {
    transform: translate(-50%, -39%) rotate(-9deg) scale(1.8, 1.8);
  }
}

.ChapterLink--tilde .ChapterLink-shape {
  transform: translate(-40%, -45%) rotate(-8deg) scale(2.6, 2.6);

  @media (--viewport-md) {
    transform: translate(-40%, -45%) rotate(-10deg) scale(2.4, 2.4);
  }

  @media (--viewport-xl) {
    transform: translate(-40%, -45%) rotate(-8deg) scale(2.6, 2.6);
  }
}

