.Accordion {
  margin-bottom: 20px;
  margin-top: 20px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 20px;
  }
}

.Accordion-item {
  border-bottom: solid 2px var(--color-black);
  margin-bottom: 30px;
}

.Accordion-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  outline: none;
  padding: 0 0 10px;
  width: 100%;

  @media (--viewport-md) {
    font-size: var(--font-size-l);
    padding: 0 0 20px;
  }

  & path,
  & svg {
    fill: currentColor;
    stroke: currentColor;
    transition-property: fill, stroke;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
  }

  &:hover svg,
  &:focus svg {
    color: var(--color-brown);
  }
}

.Accordion-button.is-open svg {
  transform: rotate(180deg);
}

.Accordion-buttonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.Accordion-buttonChevron {
  fill: var(--color-black);
  height: 10px;
  width: 10px;
  flex-shrink: 0;
  margin-left: 10px;

  @media (--viewport-md) {
    height: 16px;
    width: 16px;
  }
}

.Accordion-content {
  background-color: var(--color-gray-lightest);
  font-size: var(--font-size-m);
  overflow: hidden;
  height: 0;
}

.Accordion-contentLayout {
  padding: 10px;

  @media (--viewport-md) {
    padding: 24px;
  }
}
