.ProductStores-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  margin-bottom: 25px;
  display: inline-block;
  width: 100%;

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

.ProductStores-storesContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.ProductStores-storesContainer--toggle {
  height: 0;
}

.ProductStores-store {
  background-color: var(--color-gray-light);
  display: block;
  margin-bottom: 15px;
  margin-right: 15px;
  width: calc((100% / 3) - ((2/3) * 15px));

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media (--viewport-md) {
    width: calc((100% / 6) - ((5/6) * 15px));

    &:nth-child(3n) {
      margin-right: 15px;
    }

    &:nth-child(6n) {
      margin-right: 0;
    }
  }
}

.ProductStores-logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: grayscale(100%);
  height: 75px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: filter 200ms ease-out;
  width: 75px;

  &:hover,
  &:focus {
    filter: grayscale(0%);
  }

  @media (--viewport-md) {
    height: 100px;
    width: 100px;
  }
}

.ProductStores-showAllButton {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  margin: 10px 0 0;
  outline: none;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.ProductStores-showAllButtonIcon {
  background-color: var(--color-red);
  border-radius: 50%;
  height: 24px;
  margin-right: 10px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProductStores-showAllButtonIcon svg {
  width: 12px;
  height: 12px;
  fill: var(--color-white);
  transition: transform 200ms ease-out;
  transform: rotate(45deg);
}

.ProductStores-showAllButton.is-closed svg {
  transform: rotate(0deg);
}
