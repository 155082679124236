@import 'utilities/container';

.UpdatesOverview-header {
  margin-bottom: 30px;
  margin-top: 80px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 60px;
  }
}

.UpdatesOverview-termNav {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.UpdatesOverview-latestPostContainer {
  @media (--viewport-md) {
    @apply --container-base;

    max-width: var(--container-width-xxl);
    padding-left: calc(var(--gutter-h) * 2);
    padding-right: calc(var(--gutter-h) * 2);
  }
}

.UpdatesOverview-latestPost {
  margin-bottom: 45px;

  @media (--viewport-md) {
    margin-bottom: 200px;
  }
}

.UpdatesOverview--noPadding {
  padding-left: 0;
  padding-right: 0;
}

.UpdatesOverview-posts {
  margin-bottom: 25px;

  @media (--viewport-md) {
    margin-bottom: 50px;
  }
}

.UpdatesOverview-pagination {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.UpdatesOverview-newsletter {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}

.UpdatesOverview-aboutUsSupercharger {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}
