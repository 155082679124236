.LabelWithIcon {
  align-items: center;
  background-color: var(--color-gray-lightest);
  display: inline-flex;
  font-size: var(--font-size-m);
  padding: 8px 10px;
  line-height: 1;
}

.LabelWithIcon svg {
  flex-shrink: 0;
}

.LabelWithIcon-label {
  margin-left: 10px;
  position: relative;
  top: 1px;
}
