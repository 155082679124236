.post-ratings{
    text-align: center;
    display: flex;
    justify-content: center;
}

.post-ratings-text {
    display: none !important;
}

.post-ratings img {
    width: 21px;
    height: 18px;
    margin-bottom: 25px !important;
    padding-right: 3px !important;

    &:last-child{
        margin-right: 0;
    }
}

.post-ratings span {
    margin-left: 5px;
    font-size: 18px;
    margin-top: -4px;
}

.post-ratings-loading {
    display: none !important;
}