.ProductContents-content {
  margin-top: 0;
  margin-bottom: 20px;
  padding: 0;

  @media (--viewport-md) {
    margin-bottom: 35px;
  }
}

.ProductContents-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  margin-bottom: 25px;
  display: inline-block;
  width: 100%;

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

.ProductContents-contentItem {
  list-style-type: none;
  margin: 0;
  padding:0;
}

.ProductContents-contentItem--inset {
  padding-left: 20px;
}

.ProductContents-container {
  @media (--viewport-md) {
    display: flex;
    margin-top: 30px;
  }
}

.ProductContents-column {
  width: 100%;

  @media (--viewport-md) {
    width: 40%;

    &:first-child {
      margin-right: 40px;
      width: 80%;
    }
  }
}

.ProductContents-link {
  color: var(--color-text);
  text-transform: lowercase;
}

.ProductContents-unit {
  text-transform: lowercase;
}

