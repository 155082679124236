@import 'utilities/container';

.ProductsOverview-header {
  margin-bottom: 30px;
  margin-top: 80px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 60px;
  }
}

.ProductsOverview-termNav {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.ProductsOverview-hero {
  margin-bottom: 45px;
  position: relative;
  z-index: 1;

  @media (--viewport-md) {
    @apply --container-base;

    margin-bottom: 35px;
    max-width: var(--container-width-xl);
  }
}

.ProductsOverview-categoryTextContainer {
  position: relative;
  z-index: 2;
  margin-bottom: 25px;

  @media (--viewport-md) {
    margin-bottom: 50px;
  }
}

.ProductsOverview-categoryText {
  margin: 0;
  text-align: center;
}

.ProductsOverview-pagination {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.ProductsOverview-newsletter {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}

.ProductsOverview-aboutUsSupercharger {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}
