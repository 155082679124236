.Newsletter {
  border-bottom: solid var(--color-black) 2px;
  border-top: solid var(--color-black) 2px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.Newsletter-newsletterContainer {

  @media (--viewport-md) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.Newsletter-formContainer,
.Newsletter-infoContainer {
  @media (--viewport-md) {
    width: calc(50% - 20px);
  }
}

.Newsletter-title {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-s);

  @media (--viewport-md) {
    margin-right: 20px;
  }
}

.Newsletter-text {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin-top: 0;
  margin-bottom: 15px;
}

.Newsletter-form {
  @media (--viewport-sm) {
    display: flex;
  }
}

.Newsletter-input {
  border-radius: 6px;
  border: solid 1px var(--color-gray-medium);
  display: block;
  font-size: var(--font-size-m);
  line-height: 1;
  margin-bottom: 10px;
  padding: 15px 25px;
  width: 100%;

  @media (--viewport-sm) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    margin-bottom: 0;
  }
}

.Newsletter-submit {
  background: var(--color-red);
  border-radius: 6px;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  outline: none;
  padding: 15px 25px;
  transition: background-color 200ms ease-out;
  width: 100%;

  &:hover,
  &:focus {
    background-color: var(--color-blue);
  }

  @media (--viewport-sm) {
    display: inline-block;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: auto;
  }
}

.Newsletter-message {
  font-size: var(--font-size-m);
  display: none;
}

.Newsletter-seperator {
  margin: 25px 0;
  width: 100%;
  height: 2px;
  background: var(--color-black);
}

.Newsletter-socialBlock {
  @media (--viewport-md) {
    align-items: center;
    display: flex;
  }
}

.Newsletter-socialButtonContainer {
  align-items: center;
  display: flex;
}

.Newsletter-socialButton {
  background-color: var(--color-blue);
  border-radius: 50%;
  transition: background-color 200ms ease-out;
  color: var(--color-white);

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-black);
  }
}

.Newsletter-socialButton--red {
  background-color: var(--color-red);
}

.Newsletter-socialButton--yellow {
  background-color: var(--color-yellow);
}

.Newsletter-socialButton--brown {
  background-color: var(--color-brown);
}

.Newsletter-socialButton--blueDark {
  background-color: var(--color-marine-blue);
}

.Newsletter-socialButton svg {
  height: 24px;
  width: 24px;
}
