.u-animatedLink {
  position: relative;
  text-decoration: none !important;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: currentColor;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 250ms ease-out;
  }

  &:hover::after,
  &:focus::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
