.Carousel {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.Carousel.flickity-enabled {
  opacity: 1;
}

.Carousel-cell {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);

  @media (--viewport-md) {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  }
}

.Carousel.flickity-enabled .Carousel-cell {
  min-height: 100%;
  display: flex;

  & > * {
    flex-grow: 1;
    align-self: stretch;
  }
}

.Carousel .flickity-prev-next-button {
  background-color: var(--color-red);
  opacity: 1;
  transition: background-color 200ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  left: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color-blue);
  }
}

.Carousel .flickity-prev-next-button.next {
  transform: translateY(-52px);
}

.Carousel .flickity-prev-next-button.previous {
  transform: translateY(4px);
}

.Carousel .flickity-prev-next-button svg {
  position: relative;
  left: 0;
  top: 0;
  height: auto;
  width: 24px;
}

.Carousel .flickity-prev-next-button .arrow {
  fill: var(--color-white);
}
