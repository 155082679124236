.RecipeGridItem {
  text-decoration: none;
  color: currentColor;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  display: block;
  width: 100%;
}

.RecipeGridItem .overlay-link {
	cursor: pointer;
	display: block;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.RecipesOverview-grid .facetwp-type-pager {
	text-align: center;
	margin-top: 2rem;
}

.RecipeGridItem-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.RecipeGridItem-image {
  background-size: cover;
  background-position: center;
	overflow: hidden;
	display: block;
	font-size: 0;
}
.RecipeGridItem-image img {
	width: 100%;
  object-fit: cover;
}
img.RecipeGridItem-image.loaded {
  opacity: 1;
}
.RecipeGridItem-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 25px;
}

.RecipeGridItem-title {
  flex-grow: 1;
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin-bottom: 20px;
  text-transform: uppercase;
  max-width: 100%; /* IE11 */
  word-break: normal;
  hyphens: auto;
  overflow-wrap: break-word;
}

.Grid-item .RecipeGridItem-title {
  word-break: normal;
  hyphens: auto;
  overflow-wrap: break-word;
}
.RecipeGridItem-imageContainer {
  position: relative;
}

.RecipeGridItem-author {
  position: absolute;
  width: 110px;
  height: 110px;
  right: 10px;
  bottom: -10px;
  z-index: 1;
}

.RecipeGridItem-authorShape {
  display: none;
  position: absolute;
  z-index: -1;
  width: 124px;

  @media (--viewport-lg) {
    display: block;
  }
}

.RecipeGridItem-authorShape--golf_club {
  bottom: -26px;
  right: -44px;
  transform: rotate(-91deg);
}

.RecipeGridItem-authorShape--blob {
  bottom: -33px;
  right: -22px;
  transform: translate(-6px, -10px);
}

.RecipeGridItem-authorShape--droplet {
  bottom: -35px;
  right: -22px;
  transform: translate(80px, -20px) rotate(35deg);
  width: 220px;
}
