.AboutUsSupercharger {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (--viewport-md) {
    flex-direction: row;
    text-align: left;
  }
}

.AboutUsSupercharger-logo {
  height: auto;
  margin-bottom: 20px;
  max-width: 156px;

  @media (--viewport-md) {
    margin-bottom: 0;
    margin-right: 20px;
  }

  @media (--viewport-lg) {
    margin-right: 40px;
  }
}

.AboutUsSupercharger-text {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin-bottom: 10px;
  max-width: var(--container-width-sm);

  @media (--viewport-md) {
    font-size: var(--font-size-l);
    max-width: 100%;
  }
}

.AboutUsSupercharger-link {
  align-items: center;
  color: var(--color-text);
  display: inline-flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.1;
  text-decoration: none;
}

.AboutUsSupercharger-link svg {
  margin-left: 10px;
}
