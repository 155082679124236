.CarouselOriginalChainItem {
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

.CarouselOriginalChainItem-imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.CarouselOriginalChainItem-image {
  width: 60%;
  height: auto;
  max-width: 300px;
}

.CarouselOriginalChainItem-shapeContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.CarouselOriginalChainItem-shape {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.CarouselOriginalChainItem-content {
  position: relative;
  z-index: 1;
  margin-top: 20px;
  padding-left: 80px;

  @media (--viewport-md) {
    margin-top: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
    font-size: var(--font-size-m);
  }
}

.CarouselOriginalChainItem-description {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.CarouselOriginalChainItem--explore .CarouselOriginalChainItem-image {
  width: 70%;
}

.CarouselOriginalChainItem--cooperation {
  margin-top: 100px;
}

.CarouselOriginalChainItem--cooperation .CarouselOriginalChainItem-image {
  width: 60%;
}

.CarouselOriginalChainItem--collective .CarouselOriginalChainItem-image {
  width: 40%;
}

.CarouselOriginalChainItem--training .CarouselOriginalChainItem-image {
  width: 60%;
}

.CarouselOriginalChainItem--recipes {
  margin-top: 20px;
}

.CarouselOriginalChainItem--certification .CarouselOriginalChainItem-image {
  width: 40%;
}

.CarouselOriginalChainItem--golf_club .CarouselOriginalChainItem-shape {
  transform: translate(-10%, -20%) rotate(53deg) scale(-1.2, 1.2);
}

.CarouselOriginalChainItem--blobby .CarouselOriginalChainItem-shape {
  transform: translate(-5%, 0%) rotate(161deg) scale(0.8, 0.8);
}

.CarouselOriginalChainItem--smudge .CarouselOriginalChainItem-shape {
  transform: translate(-5%, 2%) rotate(265deg) scale(0.7, 0.7);
}

.CarouselOriginalChainItem--blob .CarouselOriginalChainItem-shape {
  transform: translate(0%, -3%) rotate(63deg) scale(0.7, 0.7);
}

.CarouselOriginalChainItem--droplet .CarouselOriginalChainItem-shape {
  transform: translate(-6%, 1%) rotate(-63deg) scale(0.45, 0.45);
}

.CarouselOriginalChainItem--leaf .CarouselOriginalChainItem-shape {
  transform: translate(0%, 1%) rotate(0) scale(0.6, 0.6);
}
