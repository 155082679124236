.AuthorBig {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.AuthorBig-info {
  display: flex;
  align-items: center;
  margin-right: 20px;
  order: 2;

  @media (--viewport-md) {
    order: 1;
    width: 50%;
  }
}

.AuthorBig-image {
  background-color: var(--color-red);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 65px;
  margin-right: 20px;
  width: 65px;

  @media (--viewport-md) {
    height: 100px;
    width: 100px;
  }
}

.AuthorBig-by {
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  margin-bottom: 4px;
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.AuthorBig-name {
  color: var(--color-red);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  margin-bottom: 4px;
  font-size: var(--font-size-s);
}

.AuthorBig-job {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: 1;
}

.AuthorBig-link {
  color: var(--color-text);
  display: none;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  margin-top: 8px;
  text-decoration: none;

  @media (--viewport-md) {
    display: block;
  }
}

.AuthorBig-bio {
  background-color: var(--color-gray-lightest);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  padding: 25px;
  order: 1;
  margin-bottom: 20px;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }

  @media (--viewport-md) {
    font-size: var(--font-size-l);
    margin-bottom: 0;
    order: 2;
    width: 50%;
  }
}
