.SocialFollow-link {
  transition: transform 200ms ease-out;
  color: var(--color-black);
  transform: scale(0.9);

  @media (--viewport-md) {
    height: 24px;
    width: 24px;
  }

  &:hover,
  &:focus {
    transform: rotate(5deg) scale(1);
  }

  & svg {
    width: 18px;
    height: 18px;
  }
}

.SocialFollow-link:nth-child(odd):hover,
.SocialFollow-link:nth-child(odd):focus {
  transform: rotate(-4deg) scale(1);
}
