.ProductIntro {
  @media (--viewport-md) {
    display: flex;
    justify-content: space-between;
  }
}

.ProductIntro-column {
  width: 100%;

  @media (--viewport-md) {
    width: 50%;

    &:first-child {
      margin-right: 70px;
    }

    &:last-child {
      position: relative;
      top: -20px;
    }
  }
}

.ProductIntro-infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewport-md) {
    align-items: flex-start;
  }
}

.ProductIntro-backLink {
  margin-top: 0;
  margin-bottom: 10px;
}

.ProductIntro-title {
  text-align: center;
  max-width: 100%; /* IE 11 */

  @media (--viewport-md) {
    margin-top: -20px;
    text-align: left;
  }
}

.ProductIntro-shortDescription {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  opacity: 0.8;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 100%; /* IE 11 */

  @media (--viewport-md) {
    font-size: var(--font-size-l);
    text-align: left;
  }
}

.ProductIntro-longDescription {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin-top: 0;
  margin-bottom: 40px;
  max-width: 100%; /* IE 11 */
}

.ProductIntro-onPageLinks--small {
  @media (--viewport-md) {
    max-width: 360px;
  }
}

.ProductIntro-imageContainer {
  display: block;
  margin-bottom: 25px;
  position: relative;

  @media (--viewport-md) {
    display: none;
  }
}

.ProductIntro-imageContainer--showOnMd {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.ProductIntro-productImage {
  display: block;
  height: 320px;
  margin-left: auto;
  margin-right: auto;
  width: auto;

  @media (--viewport-sm) {
    height: 380px;
  }

  @media (--viewport-md) {
    height: 550px;
  }
}

.ProductIntro-farmer {
  height: 150px;
  margin: -60px auto 0;
  position: relative;
  width: 150px;
  z-index: 2;

  @media (--viewport-md) {
    height: 200px;
    width: 200px;
    margin: -120px auto 0;
  }
}


.buybuttonimage {
  color: var(--color-black);
  text-decoration: none;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 140px;
  height: 104px;
  transform: rotate(-10deg);
  display: flex;
  align-items: center;
  transition: transform .5s;
  &:hover {
    transform: rotate(-3deg) scale(1.05);
  }
  @media (--viewport-md) {
    width: 232px;
    height: 172px;
  }
}
.buybuttonimage-title {
  text-shadow: -2px 1px white;
  text-align: center;
  margin: 0;
  position: absolute;
  padding-top: 13px;
  font-size: 28px;
  line-height: 33px;

  @media (--viewport-md) {
    font-size: 52px;
    line-height: 56px;
  }
}

.buybuttonimage .Shape {
  pointer-events: none;
  transform: rotate(7deg);
}
