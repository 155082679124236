/** @define FlexEmbed */

/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%;
  }
}

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */

/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed--3by1::after {
  padding-bottom: calc(100% / 3);
}

/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed--2by1::after {
  padding-bottom: 50%;
}

/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed--16by9::after {
  padding-bottom: 56.25%;
}

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed--4by3::after {
  padding-bottom: 75%;
}

/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed--16by11::after {
  padding-bottom: 68.75%;
}

/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed > * {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.FlexEmbed > .EmployeeGridItem-image {
  @media (--viewport-lg) {
    width: 320px;
  }
}

.FlexEmbed--overflowVisible {
  overflow: visible;
}
