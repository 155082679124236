.AboutUsPeople {
  max-width: 100vw;
}

.AboutUsPeople-header {
  text-align: center;
  margin-bottom: 10px;
}

.AboutUsPeople-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.AboutUsPeople-description {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin: 0 auto 20px;

  @media (--viewport-md) {
    max-width: 470px;
    font-size: var(--font-size-m);
  }
}

.AboutUsPeople-linksContainer {
  @media (--viewport-md) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.AboutUsPeople-link {
  color: var(--color-text);
  display: block;
  flex-shrink: 0;
  text-decoration: none;
  width: 100%;

  @media (--viewport-md) {
    width: 50%;
  }

  @media (--viewport-xl) {
    width: 65%;
  }
}

.AboutUsPeople-link:first-child {
  margin-bottom: 30px;
  transform: translateX(10%) rotate(1deg);

  @media (--viewport-md) {
    transform: translateX(-5%) rotate(-1deg);
  }
}

.AboutUsPeople-link:last-child {
  transform: translateX(-10%) rotate(-1deg);

  @media (--viewport-md) {
    transform: translateX(5%) rotate(2deg);
  }
}

.AboutUsPeopleCount3.AboutUsPeople-link:last-child {
  transform: translateX(-0%) rotate(-.5deg);

  @media (--viewport-md) {
    transform: translateX(0%) rotate(.5deg);
  }
}


.AboutUsPeople-linkContent {
  background-color: var(--color-white);
  padding: 20px 30px 30px;
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.2);
  bottom: 0;
  position: relative;

  @media (--viewport-md) {
    left: 80px;
    position: absolute;
  }
}

.AboutUsPeople-linkTitle {
  margin: 0;
}

.AboutUsPeople-linkButton {
  background-color: var(--color-red) !important;
  border-radius: 50%;
  bottom: 0;
  color: var(--color-white);
  left: 30px;
  position: absolute;
  transform: translateY(50%);
  transition: background-color 200ms ease-out;

  &:hover, &:focus {
    background-color: var(--color-blue) !important;
  }
}
