.ProductTimeline {
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  z-index: 1;

  @media (--viewport-md) {
    padding-bottom: 60px;
    padding-top: 60px;
    margin-bottom: -30px;
    margin-top: -30px;
  }

  @media (--viewport-md) {
    padding-bottom: 120px;
    padding-top: 120px;
    margin-bottom: -60px;
    margin-top: -60px;
  }
}

.ProductTimeline-timeline {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: -1;

  @media (--viewport-md) {
    width: 200px;
  }
}
