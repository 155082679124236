.SiteFooterMenu {
  background-color: var(--color-gray-lightest);
  color: var(--color-text);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  padding: 40px 30px;

  @media (--viewport-xl) {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    font-size: var(--font-size-s);
    height: var(--site-footer-height-lg);
  }

  @media print { display: none; }
}

.SiteFooterMenu-copyright {
  display: none;

  @media (--viewport-xl) {
    display: flex;
    align-items: center;
    font-size: var(--font-size-m);
  }
}

.SiteFooterMenu-menuAndSocialContainer {
  border-bottom: solid 1px var(--color-black);
  padding-bottom: 30px;

  @media (--viewport-md) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  @media (--viewport-xl) {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.SiteFooterMenu-bigMenu {
  margin: 0 0 10px;
  padding: 0;
  list-style-type: none;

  @media (--viewport-md) {
    align-items: center;
    display: flex;
    margin: 0;
  }
}

.SiteFooterMenu-bigMenu-item {
  line-height: 1;
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    @media (--viewport-md) {
      margin-right: 20px;
    }

    @media (--viewport-xl) {
      margin-right: 15px;
    }
  }
}

.SiteFooterMenu-bigMenu-link {
  color: var(--color-text);
  text-decoration: none;
  line-height: 1;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.SiteFooterMenu-socialFollow {
  align-items: center;
  display: flex;

  @media (--viewport-md) {
    margin-left: 20px;
  }

  @media (--viewport-xl) {
    margin-left: 50px;
  }
}

.SiteFooterMenu-sideMenuContainer {
  margin: 30px 0 0;

  @media (--viewport-sm) {
    align-items: center;
    display: flex;
  }

  @media (--viewport-xl) {
    margin: 0;
  }
}

.SiteFooterMenu-mobileCopyright {
  display: block;
  font-size: var(--font-size-s);
  margin-bottom: 20px;

  @media (--viewport-sm) {
    display: inline;
    margin-bottom: 0;
    font-size: var(--font-size-m);
    margin-right: 40px;
  }

  @media (--viewport-xl) {
    display: none;
  }
}

.SiteFooterMenu-sideMenu {
  align-items: center;
  display: flex;
  font-family: var(--font-family-base);
  list-style-type: none;
  padding: 0;
}

.SiteFooterMenu-sideMenu-item {
  line-height: 1;

  &:not(:last-child) {
    margin-right: 20px;

    @media (--viewport-xl) {
      margin-right: 10px;
    }

    &::after {
      content: '•';
      color: var(--color-gray-medium);
      margin-left: 20px;

      @media (--viewport-xl) {
        margin-left: 10px;
      }
    }
  }
}

.SiteFooterMenu-sideMenu-link {
  color: var(--color-text);
  text-decoration: none;
  line-height: 1;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
