.ButtonIcon {
  /* reset */
  cursor: pointer;
  display: inline-block;
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: none;
  width: 48px;
  height: 48px;
}

.ButtonIcon-layout {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  & path,
  & svg {
    fill: currentColor;
    stroke: currentColor;
    transition-duration: 200ms;
    transition-property: fill, stroke;
    transition-timing-function: ease-in-out;
  }
}

.ButtonIcon-animatedContainer {
  display: flex;
}
