.Farmer {
  width: 100%;
  height: 100%;
}

.Farmer--link {
  display: block;
}

.Farmer-image {
  /* assume images are always in portrait mode */
  height: 100%;
  width: auto;
  pointer-events: none;
}
