.ContentCarouselItem {
  padding-top: 10px;
}

.ContentCarouselItem-image {
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  transform: rotate(-1deg);

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.ContentCarouselItem--even .ContentCarouselItem-image {
  transform: rotate(1.2deg);
}

.ContentCarouselItem-caption {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}
