.Employees {
  padding-top: 40px;

  @media (--viewport-md) {
    padding-top: 60px;
  }
}

.Employees-header {
  padding-bottom: 40px;

  @media (--viewport-md) {
    padding-bottom: 60px;
  }
}

.Employees-grid,
.Employees-aboutUs {
  padding-bottom: 50px;

  @media (--viewport-md) {
    padding-bottom: 80px;
  }
}
