.Video {
  transform: rotate(-0.5deg);
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  /*padding-top: 56.25%;*/
  overflow: hidden;
  cursor: pointer;
}
.Video img {
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
}
.Video .play-button {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
}
.Video:hover .play-button  {
  background-color: #FF0000;
}
.Video .play-button:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff;
}
.Video img,
.Video .play-button {
  cursor: pointer;
}
.Video img,
.Video iframe,
.Video .play-button,
.Video .play-button:before {
  position: absolute;
}
.Video .play-button,
.Video .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}
.Video iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

