*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  overflow-x: hidden;
  width: 100vw;
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  background-color: var(--color-white);
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

body.is-drawer-open {
  height: 100vh;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}
