.UpdateGridItem {
  text-decoration: none;
  color: currentColor;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  display: block;
}

.UpdateGridItem-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.UpdateGridItem-image {
  background-size: cover;
  background-position: center;
}

.UpdateGridItem-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 25px;
}

.UpdateGridItem-date {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: 1;
}

.UpdateGridItem-title {
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 15px;
  flex-grow: 1;
  font-size: var(--font-size-m);
  max-width: 100%; /* IE 11*/
}

.UpdateGridItem-imageContainer {
  position: relative;
}
