.Search-pageHeader {
  margin-top: 50px;
  margin-bottom: 30px;
}

.Search-pageHeader--moreMargin {
  margin-top: 100px;

  @media (--viewport-md) {
    margin-top: 150px;
  }
}

.Search-form {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.Search-form--moreMargin {
  margin-bottom: 100px;

  @media (--viewport-md) {
    margin-bottom: 150px;
  }
}

.Search-aboutUs {
  margin-top: 65px;
  margin-bottom: 65px;

  @media (--viewport-md) {
    margin-top: 100px;
  }
}
