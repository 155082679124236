.Quote {
  margin: 60px auto;
}

.Quote-block {
  & a {
      border-bottom: 2px solid transparent;
      color: var(--color-link-link);
      text-decoration: none;
      transition-duration: 200ms;
      transition-property: border-bottom-color, color;
      transition-timing-function: ease-in-out;

  &:hover,
  &:focus {
     border-bottom: 2px solid var(--color-link-hover);
     color: var(--color-link-hover);
   }
  }
}

.Quote-block {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-l);
  margin: 0;
  text-align: center;
}

.Quote-twitterShare {
  display: block;
  height: 32px;
  margin: 24px auto 0;
  text-decoration: none;
  width: 32px;
}

.Quote-twitterShare svg {
  fill: var(--color-black);
  height: 100%;
  width: 100%;
}
