.recipe-50-50 {
	margin-bottom: 40px;
}

.recipe-50-50 h2 {
	margin: 0 0 3rem;
}

.recipe-50-50 .image-container {
	overflow: hidden;
	position: relative;
}

.recipe-50-50 .image-container a.top {
	height: 72%;
	left: 0;
	position: absolute;
	top: -22.6%;
	transform: skewY(16.5deg);
	width: 100%;
}

.recipe-50-50 .image-container a.bottom {
	bottom: -21.2%;
	height: 72%;
	left: 0;
	position: absolute;
	transform: skewY(16.5deg);
	width: 100%;
}

@media screen and (max-width: 640px) {
	.recipe-50-50 .image-container {
		margin: 0 -20px;
	}
}

.recipe-50-50 .image-container img {
	aspect-ratio: 1.5;
	object-fit: cover;
	width: 100%;
}

.recipe-50-50 .card-wrapper {
	background-color: #F4F4F4;
	box-shadow: 0px 21px 14px -10px rgba(0, 0, 0, 0.05);
	font-family: 'Ciutadella Slab', Georgia, Times, 'Times New Roman', serif;
  line-height: 1.1;
	padding: 30px 40px 55px;
	position: relative;
	width: 40%;
	z-index: 1;
}

@media screen and ( max-width: 640px ) {
	.recipe-50-50 .card-wrapper {
		padding: 20px 20px 45px;
		width: 100%;
	}
}

@media screen and (min-width: 641px) and ( max-width: 1024px ) {
	.recipe-50-50 .card-wrapper {
		padding: 20px 20px 45px;
		width: 70%;
	}
}

.recipe-50-50 .card-wrapper.left {
	margin-bottom: -100px;
	margin-left: 30px;
}

@media screen and ( max-width: 640px ) {
	.recipe-50-50 .card-wrapper.left {
		margin-bottom: -20px;
		margin-left: 0;
	}
}

@media screen and (min-width: 641px) and ( max-width: 1024px ) {
	.recipe-50-50 .card-wrapper.left {
		margin-bottom: -64px;
	}
}

.recipe-50-50 .card-wrapper.right {
	margin-left: auto;
	margin-right: 30px;
	margin-top: -100px;
}

@media screen and ( max-width: 640px ) {
	.recipe-50-50 .card-wrapper.right {
		margin-left: 0;
		margin-right: 0;
		margin-top: -20px;
	}
}

@media screen and (min-width: 641px) and ( max-width: 1024px ) {
	.recipe-50-50 .card-wrapper.right {
		margin-top: -64px;
	}
}

.recipe-50-50 .card-wrapper .card-label {
  color: #e73c31;
  font-family: 'Ciutadella Slab', Georgia, Times, 'Times New Roman', serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}
.recipe-50-50 .card-wrapper .card-title {
	font-family: 'Americane', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0;
  margin-top: 0;
}

@media screen and (min-width: 48em) {
  .recipe-50-50 .card-wrapper .card-title {
    font-size: 6.2rem;
  }
}

.recipe-50-50 .card-wrapper .card-desc {
	font-size: 20px;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
}

.recipe-50-50 .card-wrapper .recipe-50-50-button {
  background-color: #E63B31;
  border-radius: 50%;
	bottom: 0;
  color: #fff;
  height: 48px;
	pointer-events: none;
	position: absolute;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  width: 48px;
}

.recipe-50-50 .card-wrapper a {
	display: block;
	inset: 0;
	position: absolute;
}