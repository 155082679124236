.SearchForm {
  border-bottom: solid 2px var(--color-black);
  display: flex;
  padding-bottom: 8px;
  position: relative;

  @media (--viewport-md) {
    padding-bottom: 14px;
  }
}

.SearchForm-desktopIcon {
  display: none;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  & svg {
    height: 32px;
    width: 32px;
  }

  @media (--viewport-md) {
    display: block;
  }
}

.SearchForm-input {
  background-color: transparent;
  border: none;
  flex: 1;
  outline: none;

  @media (--viewport-md) {
    padding-left: 54px;
  }
}

.SearchForm-button {
  background-color: var(--color-red);
  border-radius: 50%;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  height: 48px;
  line-height: 1;
  outline: none;
  position: relative;
  text-transform: uppercase;
  transition: background-color 200ms ease-out;
  width: 48px;

  &:hover,
  &:focus {
    background-color: var(--color-blue);
  }

  @media (--viewport-md) {
    border-radius: 5px;
    height: auto;
    padding: 20px 30px;
    width: auto;
  }
}

.SearchForm-buttonLayout {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SearchForm-text {
  display: none;

  @media (--viewport-md) {
    display: inline;
  }
}

.SearchForm-icon {
  width: 24px;
  height: 24px;

  @media (--viewport-md) {
    display: none;
  }
}

.SearchForm-icon svg {
  fill: var(--color-white);
  height: 24px;
  width: 24px;
}
