.Preparation-title {
  margin-bottom: 20px;
}

.Preparation-list {
  font-size: var(--font-size-s);
  margin-bottom: 30px;
  margin-top: 0;
  padding-left: 40px;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.Preparation-listItem {
  counter-increment: list;
  list-style-type: none;
  margin-bottom: 20px;
  position: relative;

  &::after {
    content: counter(list);
    align-items: center;
    background-color: var(--color-red);
    border-radius: 50%;
    color: var(--color-white);
    display: flex;
    font-size: var(--font-size-xs);
    height: 24px;
    justify-content: center;
    left: -40px;
    line-height: 1;
    position: absolute;
    text-align: right;
    top: 4px;
    width: 24px;
  }
}

.Preparation-info {
  font-size: var(--font-size-s);
  margin-top: 20px;
  margin-bottom: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}
