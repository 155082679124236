:root {
  --logo-height: 65px;
  --logo-height-md: 101px;
}

.SiteHeader {
  background-color: var(--color-gray-lightest);
  height: var(--site-header-height);
  left: 0;
  position: fixed;
  transform: translateY(calc((var(--site-header-height) + var(--logo-height)) * -1));
  transition: transform 200ms ease-in-out;
  width: 100%;
  z-index: var(--z-index-site-header);

  @media (--viewport-md) {
    height: var(--site-header-height-md);
    transform: translateY(calc((var(--site-header-height-md) + var(--logo-height-md)) * -1));
  }
}

.SiteHeader[data-scrolldir='up'] {
  transform: translateY(0);

  @media (--viewport-md) {
    transform: translateY(0);
  }
}

.SiteHeader.is-drawerOpen {
  background-color: transparent;
}

.SiteHeader-layout {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.SiteHeader-inset {
  display: none;

  @media (--viewport-sm) {
    display: block;
    position: absolute;
    top: 0;
    left: 30%;
    pointer-events: none;
    transform: translateY(-20%);
  }

  @media print { display: none; }
}

.SiteHeader.is-drawerOpen .SiteHeader-inset {
  display: none;
}

.SiteHeader-logo {
  width: auto;
  height: var(--logo-height);
  position: relative;
  top: 26px;

  @media (--viewport-md) {
    top: 56px;
    height: var(--logo-height-md);
    left: 20px;
  }

  @media print { top: 0; }
}

.SiteHeader-logoImage {
  height: 100%;
  width: auto;
}

.SiteHeader-drawerToggle {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  cursor: pointer;

  /* reset */
  border: none;
  background-color: transparent;
  padding: 0;

  & path,
  & svg {
    fill: currentColor;
    stroke: currentColor;
    transition-property: fill, stroke;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
  }
}

.SiteHeader-drawerToggleCross {
  display: none;
}

.SiteHeader.is-drawerOpen .SiteHeader-drawerToggleCross {
  display: inline-block;
}

.SiteHeader.is-drawerOpen .SiteHeader-drawerToggleHamburger {
  display: none;
}

.SiteHeader-drawerToggleLayout {
  align-items: center;
  display: flex;
  height: 48px;
  transition: fill 200ms ease-in-out;
  stroke: currentColor;
  padding-left: 10px;

  @media (--viewport-md) {
    padding-right: 10px;
  }
}

.SiteHeader-drawerToggleLabel {
  margin-right: 10px;
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.SiteHeader-drawerToggleIcon {
  width: 16px;
  height: 16px;
}

.SiteHeader-actions {
  align-items: center;
  display: flex;

  @media print { display: none; }
}

.SiteHeader-searchLink {
  width: 18px;
  height: 18px;
}

.SiteHeader-shopLinkContainer {
  padding-left: 20px;
  margin-left: 14px;
  border-left: 1px solid var(--color-gray-light);
}

.SiteHeader-shopLink {
  align-items: center;
  display: flex;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  text-decoration: none;

  & > svg {
    flex-shrink: 0;
    margin-right: 6px;
  }
}

.SiteHeader-shopLinkLabel {
  display: none;
  flex-shrink: 0;

  @media (--viewport-md) {
    display: block;
  }
}

.SiteHeader-searchLink,
.SiteHeader-shopLink,
.SiteHeader-drawerToggle {
  color: var(--color-black);
  transition: color 200ms ease-in-out;
  outline: none;

  &:hover,
  &:focus {
    color: var(--color-greenish-blue);
  }
}
