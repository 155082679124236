.LocalVideo {
  background-color: var(--color-black);
  position: relative;
  transform: rotate(-0.5deg);
}

.LocalVideo video[poster] {
  height: calc(100% + 7px);
}

.LocalVideo-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.LocalVideo-buttonLayout {
  align-items: center;
  display: flex;
  justify-content: center;
}

.LocalVideo-buttonLayout svg {
  fill: var(--color-white);
  height: 96px;
  transition: all 200ms ease-in-out;
  width: 96px;
  will-change: transform;
}

.LocalVideo.is-playing .LocalVideo-button {
  pointer-events: none;
  opacity: 0;
}

.LocalVideo-button:hover .LocalVideo-buttonLayout svg,
.LocalVideo-button:focus .LocalVideo-buttonLayout svg {
  transform: rotate(2deg) scale(1.05);
}

.LocalVideo-blobbyContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(43%);
  width: 130px;
  pointer-events: none;

  @media (--viewport-md) {
    width: 250px;
    transform: translateY(35%) translateX(-13%);
  }

  @media (--viewport-xl) {
    bottom: 50px;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1280px) {
  .LocalVideo-blobbyContainer {
    transform: translateY(50%)!important;
  }
}

.LocalVideo-blobbyText {
  color: var(--color-white);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
  left: 50%;
  line-height: var(--line-height-s);
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
  width: 80%;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.Content-item--video_banner {
  margin-bottom: 120px;

  @media (--viewport-md) {
    @apply --container-base;

    max-width: var(--container-width-xl);
  }
}
