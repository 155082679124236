.ProductGridItem {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  color: currentColor;
  display: block;
  text-decoration: none;
  width: 100%;
}

.ProductGridItem-imageContainer {
  align-items: center;
  background-color: var(--color-gray-lightest);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (--viewport-md) {
    height: 300px;
  }
}

.ProductGridItem-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 75%;
  transition: transform 200ms ease-in-out;
  width: 75%;
}

.ProductGridItem-new {
  height: 59px;
  position: absolute;
  right: 50px;
  top: 20px;
  width: 65px;
}

.ProductGridItem-body {
  padding: 20px 10px;
  text-align: center;
}

.ProductGridItem-title {
  font-size: var(--font-size-m);
  line-height: var(--line-height-heading);
  margin: 0 auto 8px;
  max-width: 100%; /* IE 11*/
}

.ProductGridItem-amount {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: 1;
}
