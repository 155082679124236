.u-boxShadowOnHover {
  position: relative;

  &:hover::after,
  &:focus::after {
    opacity: 1;
  }

  &::after {
    content: '';
    box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 200ms ease-out;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
