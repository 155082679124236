.CookieBar {
  background-color: var(--color-white);
  border-top: solid 1px var(--color-gray-medium);
  bottom: 0;
  font-size: var(--font-size-xs);
  padding-bottom: 20px;
  padding-top: 20px;
  position: fixed;
  width: 100%;
  z-index: var(--z-index-cookie-bar);
	display: none !important;
}

.CookieBar-layout {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.CookieBar-link {
  color: var(--color-text);
}

.CookieBar-buttonContainer {
  margin-top: 10px;
  display: flex;

  @media (--viewport-md) {
    margin-left: 20px;
    margin-top: 0;
  }
}

.CookieBar-close,
.CookieBar-acceptTracking {
  border: none;
  cursor: pointer;
  display: block;
  line-height: 1;
  padding: 10px 15px;
}

.CookieBar-acceptTracking {
  background-color: var(--color-red);
  border-radius: 6px;
  color: var(--color-white);
  transition: background-color 200ms ease-out;
  margin-right: 20px;

  &:hover,
  &:focus {
    background-color: var(--color-blue);
  }
}

.CookieBar-close {
  color: var(--color-black);
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  transition: color 200ms ease-out;

  &:hover,
  &:focus {
    color: var(--color-blue);
  }
}
