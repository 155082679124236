.ProductRelatedRecipes-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  margin-bottom: 25px;
  display: inline-block;
  width: 100%;

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

.ProductRelatedRecipes-caroussel {
  margin-left: -10px;
  margin-right: -10px;

  @media (--viewport-md) {
    margin-left: -20px;
    margin-right: -20px;
  }
}
