.Divider {
  padding-bottom: 150px;
  padding-top: 150px;
  position: relative;
}

.Divider-line {
  background-color: var(--color-black);
  height: 2px;
  width: 100%;
}

.Divider-inset {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;

  @media (--viewport-md) {
    width: 200px;
  }
}

.Divider-shape {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(40%, -50%);
  width: 100px;

  @media (--viewport-md) {
    transform: translateY(-50%);
  }
}

.Divider-shape2 {
  display: none;
  position: absolute;
  right: 20%;
  top: 30%;
  transform: translateY(-50%) rotate(80deg);
  width: 200px;

  @media (--viewport-md) {
    display: block;
  }
}
