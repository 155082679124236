.HeroImage {
  transform: translate3d(0, 0, 0) rotate(0.5deg);
  margin: -6px; /* cancels paper tear offset */
  position: relative;
  z-index: 1;

  @media print { display: none; }
}

.HeroImage-flexEmbed::after {
  padding-bottom: 56.5%;

  @media (--viewport-md) {
    padding-bottom: 56.5%;
  }

  @media (--viewport-lg) {
    padding-bottom: 39.0625%;
  }
}

.HeroImage--small .HeroImage-flexEmbed::after {
  @media (--viewport-lg) {
    padding-bottom: 34.66019417%;
  }
}

.HeroImage-inset,
.HeroImage-shape {
  width: 100px;
  position: absolute;

  @media (--viewport-md) {
    width: 200px;
  }
}

.HeroImage-inset--bottom,
.HeroImage-shape--bottom {
  bottom: 0;
}

.HeroImage-inset--top,
.HeroImage-shape--top {
  top: 0;
}

.HeroImage-inset--left,
.HeroImage-shape--left {
  left: 0;
}

.HeroImage-inset--right,
.HeroImage-shape--right {
  right: 0;
}

.HeroImage-inset--bottom.HeroImage-inset--left,
.HeroImage-shape--bottom.HeroImage-shape--left {
  transform: translate(-50%, 50%);
}

.HeroImage-inset--bottom.HeroImage-inset--right,
.HeroImage-shape--bottom.HeroImage-shape--right {
  transform: translate(50%, 50%);
}

.HeroImage-inset--top.HeroImage-inset--left,
.HeroImage-shape--top.HeroImage-shape--left {
  transform: translate(-50%, -50%);
}

.HeroImage-inset--top.HeroImage-inset--right,
.HeroImage-shape--top.HeroImage-shape--right {
  transform: translate(50%, -50%);
}
