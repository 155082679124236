

.ContactForm {
  background: var(--color-white);
  border: 1px solid var(--color-gray-light);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  flex-grow: 1;

  @media (--viewport-md) {
    padding: 80px 40px;
  }
}

.ContactForm-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-l);
  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
    font-size: var(--font-size-xl);
  }
}

.ContactForm-formContainer {
  display: flex;
  flex-direction: column;
}

.ContactForm-label {
  display: block;
  position: relative;
}

.ContactForm-label--checkbox {
  border-bottom: 1px solid var(--color-gray-light);
  margin-bottom: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ContactForm-label--checkbox .ContactForm-labelText {
  color: color(var(--color-text) alpha(50%));
}

.ContactForm-checkbox {
  opacity: 0;
  position: absolute;
}

.ContactForm-peudoCheckbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-gray-medium);
  border-radius: 6px;
  margin-right: 10px;
  background-color: var(--color-gray-lightest);
  transition: background-color 200ms ease-in-out;
}

.ContactForm-checkbox:checked ~ .ContactForm-peudoCheckbox {
  background-color: var(--color-red);
}

.ContactForm-input {
  height: 65px;
  vertical-align: middle;
}

.ContactForm-textarea {
  resize: vertical;
  min-height: 200px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.ContactForm-input,
.ContactForm-textarea {
  border-bottom: 1px solid var(--color-gray-light);
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
  transition: border-color 200ms ease-in-out;
  vertical-align: middle;
  width: 100%;

  &::placeholder {
    color: color(var(--color-text) alpha(50%));
  }

  &:focus {
    border-color: var(--color-gray-medium);
    outline: none;
  }
}

.ContactForm-errorIndicator {
  position: absolute;
  right: 20px;
  stroke: var(--color-red);
  top: 24px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ContactForm-label.is-invalid .ContactForm-input,
.ContactForm-label.is-invalid .ContactForm-textarea,
.ContactForm-label.is-touched:invalid .ContactForm-input,
.ContactForm-label.is-touched:invalid .ContactForm-textarea {
  border-color: var(--color-red);
}

.ContactForm-label.is-invalid .ContactForm-errorIndicator {
  opacity: 1;
}

.ContactForm-notify:not(:empty) {
  margin-bottom: 20px;
  color: var(--color-early-green);
  background: var(--color-white);
  border: 1px solid var(--color-gray-light);
  padding: 20px;

  @media (--viewport-md) {
    padding: 40px;
  }
}

.ContactForm-notify.is-error {
  color: var(--color-red);
}

.ContactForm-button {
  background: var(--color-red);
  border-radius: 6px;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  outline: none;
  padding: 15px 25px;
  transition: background-color 200ms ease-out;

  & svg {
    fill: var(--color-white);
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-blue);
  }

  @media (--viewport-md) {
    align-self: flex-end;
  }

  &.is-busy {
      pointer-events: none;
   }
   &.is-send {
      pointer-events: none;
      opacity: 0.3;
   }
}

.ContactForm-buttonContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ContactForm-fileInputWrapper {
  position: relative;
}

.ContactForm-fileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  outline: none;
  opacity: 0;
}

.ContactForm-fileInputCover {
  background-color: var(--color-white);
  border-radius: 6px;
  border: 1px solid var(--color-gray-medium);
  color: color(var(--color-text) alpha(50%));
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding: 6px 10px;
  transition: background-color 200ms ease-in-out;
  margin-bottom: 20px;
}

.ContactForm-fileInputCover:hover,
.ContactForm-fileInput:focus .ContactForm-fileInputCover {
  background-color: var(--color-gray-light);
}

.ContactForm-fileInputIcon {
  fill: var(--color-red);
  display: inline-block;
  flex-shrink: 0;
  transform: rotate(-90deg);
}

.ContactForm-label.is-hidden {
  display: none;
}

.ContactForm-fileInputText {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.ContactForm-field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}