.SiteLayout-main > .SingleProduct {
    overflow-x: hidden;
}

.custom-anchorpoints-relative {
    position: relative;
}
.Video.custom-anchorpoints-relative {
    overflow: visible;
}

#wp-admin-bar-show-anchors {
    cursor: pointer;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.anchors--are--active #wp-admin-bar-show-anchors {
    color: green;
}

.custom-anchorpoints {
    top: -100px;
    position: absolute;
    opacity: 0;
    z-index: 9999;
}

.custom-anchorpoints--value {
    top: 100px;
    position: relative;
    transform: rotate(-5deg) translateY(-100%);
    color: white;
    background: #cfcb8a;
    color: #261c14;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px black;
    text-transform: none;
    font-weight: normal;
    display: inline-block;
    font-size: 25px;
}

.anchors--are--active .custom-anchorpoints {
    opacity: 1;
}