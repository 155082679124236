.Contact-pageHeader {
  margin-top: 50px;
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-top: 70px;
  }
}

.Contact-onPageLinks {
  margin-bottom: 40px;

  @media (--viewport-md) {
    display: none;
  }
}

.Contact-contentFlexContainer {
  margin-bottom: 120px;

  @media (--viewport-md) {
    align-items: flex-end;
    display: flex;
    margin-bottom: 80px;
  }
}

.Contact-form {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 0;
  }
}

.Contact-address {
  @media (--viewport-md) {
    flex-shrink: 0;
    margin-left: 65px;
    margin-bottom: 80px;
  }
}

.Contact-aboutUs {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}
