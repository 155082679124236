.CarouselOriginalChain-label {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-l);
  opacity: 0.8;
  line-height: 1.1;

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
  }
}

.CarouselOriginalChain-title {
  position: relative;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 80px;
}

.CarouselTimeline-cell {
  width: 100%;

  &:not(:last-child) {
    margin-right: 200px;

    @media (--viewport-lg) {
      margin-right: 300px;
    }
  }
}

.CarouselTimeline-layout {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media (--viewport-lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.CarouselTimeline-content,
.CarouselTimeline-imageContainer {
  @media (--viewport-lg) {
    width: calc(50% - 10px);
  }
}

.CarouselTimeline-content {
  padding-left: 20px;
  padding-right: 20px;

  @media (--viewport-sm) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (--viewport-md) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (--viewport-lg) {
    padding-left: 100px;
    padding-right: 0;
  }
}

.CarouselTimeline-year {
  display: none;

  @media (--viewport-lg) {
    display: block;
    position: relative;
    font-size: 16rem;
    opacity: 0.1;
    line-height: 1;
    left: -80px;
  }
}

.CarouselTimeline-description {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);

  @media (--viewport-lg) {
    font-size: var(--font-size-m);
  }
}

.CarouselTimeline-slideTitle {
  @media (--viewport-md) {
    font-size: var(--font-size-l);
  }

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
  }
}

.CarouselTimeline-imageContainer {
  margin-bottom: 100px;
  max-width: 780px;

  @media (--viewport-md) {
    padding-top: 20px;
  }
}

.CarouselTimeline-image {
  transform: translateX(10%) rotate(1.5deg);

  @media (--viewport-lg) {
    width: 130%;
  }

  @media (--viewport-xl) {
    width: 100%;
  }
}

.CarouselTimeline-layout--even .CarouselTimeline-image {
  transform: translateX(10%)  rotate(-2deg);
}

.CarouselTimeline .flickity-page-dots {
  bottom: auto;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: calc(100vw * 0.6875 + 40px);
  width: 100%;

  @media (--viewport-md) {
    top: calc(100vw * 0.6875 - 40px);
  }

  @media (--viewport-lg) {
    top: auto;
    bottom: 30px;
  }
}

.CarouselTimeline .dot {
  background-color: var(--color-white);
  border: 6px solid var(--color-gray-light);
  cursor: pointer;
  height: 16px;
  opacity: 1;
  transition: border-color 200ms ease-in-out;
  width: 16px;

  @media (--viewport-md) {
    margin: 0 15px;
  }
}

.CarouselTimeline .dot:hover,
.CarouselTimeline .dot.is-selected {
  border-color: var(--color-red);
}

.CarouselTimeline .flickity-prev-next-button {
  background-color: var(--color-red);
  opacity: 1;
  transition: background-color 200ms ease-out;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  left: 14px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue);
  }
}

.CarouselTimeline .flickity-prev-next-button.next {
  top: calc(100% * (0.6875 / 2) - 53px);

  @media (--viewport-lg) {
    top: auto;
    bottom: 205px;
  }
}

.CarouselTimeline .flickity-prev-next-button.previous {
  top: calc(100% * (0.6875 / 2) + 5px);

  @media (--viewport-lg) {
    top: auto;
    bottom: 143px;
  }
}

.CarouselTimeline .flickity-prev-next-button[disabled] {
  background-color: var(--color-gray-light);
}

.CarouselTimeline .flickity-prev-next-button svg {
  position: relative;
  left: 0;
  top: 0;
  height: auto;
  width: 24px;
}

.CarouselTimeline .flickity-prev-next-button .arrow {
  fill: var(--color-white);
}

.Content-item--block_timeline {
	margin-bottom: 100px;

	@media ( --viewport-xl ) {
		width: calc(50vw + 512px);
		margin-left: calc(50vw - 512px);
		margin-bottom: 120px;
	}
}
