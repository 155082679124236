.SearchNoResults {
  background: var(--color-white);
  border: 1px solid var(--color-gray-light);
  color: var(--color-text);
  display: block;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;

  @media (--viewport-md) {
    padding: 45px;
  }
}

.SearchNoResults-title {
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
  }
}

.SearchNoResults-description {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.SearchNoResults-link {
  color: var(--color-red);
  font-weight: var(--font-weight-semi-bold);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
