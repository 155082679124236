.PageHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.PageHeader-layout {
  max-width: 100%; /* IE 11*/
}

.PageHeader-description {
  font-size: var(--font-size-m);
  font-family: var(--font-family-slab);
  color: var(--color-text-light);

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-s);
  }
}

.PageHeader-description a {
  color: var(--color-red);
  font-weight: var(--font-weight-medium);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.PageHeader-backLink {
  display: inline-block;

  @media print { display: none; }
}
