@import 'utilities/container';

.AboutUs-pageHeader {
  margin-bottom: 20px;
  margin-top: 50px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 80px;
  }
}

.AboutUs-onPageLinks {
  margin-bottom: 30px;

  @media (--viewport-md) {
    margin-bottom: 50px;
  }
}

.AboutUs-video {
  margin-bottom: 120px;

  @media (--viewport-md) {
    @apply --container-base;

    max-width: var(--container-width-xl);
  }
}

.AboutUs-mission {
  background-image: url(../images/timeline.svg);
  background-position: top center;
  background-repeat: no-repeat;
  margin-bottom: 120px;
}

.AboutUs-originalChainLayout,
.AboutUs-history {
  margin-bottom: 100px;

  @media (--viewport-xl) {
    width: calc((100vw - 1024px) / 2 + 1024px);
    margin-left: calc((100vw - 1024px) / 2);
    margin-bottom: 120px;
  }
}

.AboutUs-method {
  margin-bottom: 120px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}

.AboutUs-people {
  margin-bottom: 140px;
}

.AboutUs-foodForThought {
  margin-bottom: 130px;
}

.AboutUs-newsletter {
  margin-bottom: 70px;

  @media (--viewport-md) {
    margin-bottom: 110px;
  }
}
