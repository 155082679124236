.EmployeeGridItem {
  text-decoration: none;
  color: currentColor;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  display: block;
}

.EmployeeGridItem-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.EmployeeGridItem-image {
  background-size: cover;
  background-position: center;
  transition: transform 200ms ease-in-out;
}

.EmployeeGridItem-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 25px;
}

.EmployeeGridItem-name {
  text-transform: uppercase;
  font-size: var(--font-size-m);
  margin-bottom: 8px;
  flex-grow: 1;
}

.EmployeeGridItem-jobTitle {
  margin-top: auto;
  font-size: var(--font-size-s);
}

