.alertify .ajs-dialog {
	-webkit-box-shadow: 0 12px 24px -10px rgba(0, 0, 0, .2);
	box-shadow: 0 12px 24px -10px rgba(0, 0, 0, .2);

	-webkit-transform: translate3d(0, 0, 0) rotate(-2deg);
	transform: translate3d(0, 0, 0) rotate(-2deg);
	overflow: hidden;
	max-width: 600px;
}

#mp-popupwrapper3 {display:none !important};

.alertify .ajs-header {
	font-family: var(--font-family-slab);
	font-weight: 500;
	line-height: 1.1;
	color: currentColor;
	margin-bottom: 0.1em;
	font-size: 2.4rem;
	text-transform: uppercase;
}

.alertify .ajs-commands button {
	width: 16px;
	height: 16px;
}

.alertify .ajs-commands button.ajs-close {
	background-image: none;
	position: relative;
	padding: 0;
	&::before,
	&::after {
		content: "";
		position: relative;
		width: 16px;
		height: 16px;
		display: inline-block;
		background-repeat: no-repeat;
		top: 0;
		left: 0;
		transition: opacity .5s;
		 float: left;
	}
	&::before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBzdHlsZT0id2lkdGg6IDEwMCU7IGhlaWdodDogMTAwJTsgdHJhbnNmb3JtOiB0cmFuc2xhdGUzZCgwcHgsIDBweCwgMHB4KTsiPjxkZWZzPjxjbGlwUGF0aCBpZD0iX19sb3R0aWVfZWxlbWVudF81MCI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4PSIwIiB5PSIwIj48L3JlY3Q+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xpcC1wYXRoPSJ1cmwoI19fbG90dGllX2VsZW1lbnRfNTApIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDAsMCkiIG9wYWNpdHk9IjEiIHN0eWxlPSJkaXNwbGF5OiBibG9jazsiPjxnIG9wYWNpdHk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDEsMCwwLDEsOCw4KSI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBmaWxsLW9wYWNpdHk9IjAiIHN0cm9rZT0icmdiKDAsMCwwKSIgc3Ryb2tlLW9wYWNpdHk9IjEiIHN0cm9rZS13aWR0aD0iMiIgZD0iIE0tNiw2IEMtNiw2IDYsLTYgNiwtNiI+PC9wYXRoPjwvZz48ZyBvcGFjaXR5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDgsOCkiPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbC1vcGFjaXR5PSIwIiBzdHJva2U9InJnYigwLDAsMCkiIHN0cm9rZS1vcGFjaXR5PSIxIiBzdHJva2Utd2lkdGg9IjIiIGQ9IiBNLTYsLTYgQy02LC02IDYsNiA2LDYiPjwvcGF0aD48L2c+PC9nPjxnIHN0eWxlPSJkaXNwbGF5OiBub25lOyIgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwwLDApIiBvcGFjaXR5PSIxIj48ZyBvcGFjaXR5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDgsOCkiPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbC1vcGFjaXR5PSIwIiBzdHJva2U9InJnYigwLDAsMCkiIHN0cm9rZS1vcGFjaXR5PSIxIiBzdHJva2Utd2lkdGg9IjIiIGQ9IiBNLTYsNiBDLTYsNiA2LC02IDYsLTYiPjwvcGF0aD48L2c+PGcgb3BhY2l0eT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSw4LDgpIj48cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGZpbGwtb3BhY2l0eT0iMCIgc3Ryb2tlPSJyZ2IoMCwwLDApIiBzdHJva2Utb3BhY2l0eT0iMSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSIgTS02LC02IEMtNiwtNiA2LDYgNiw2Ij48L3BhdGg+PC9nPjwvZz48L2c+PC9zdmc+);
	}
	&::after {
		opacity: 0;
		position: absolute;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBzdHlsZT0id2lkdGg6IDEwMCU7IGhlaWdodDogMTAwJTsgdHJhbnNmb3JtOiB0cmFuc2xhdGUzZCgwcHgsIDBweCwgMHB4KTsiPjxkZWZzPjxjbGlwUGF0aCBpZD0iX19sb3R0aWVfZWxlbWVudF81MCI+PHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4PSIwIiB5PSIwIj48L3JlY3Q+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xpcC1wYXRoPSJ1cmwoI19fbG90dGllX2VsZW1lbnRfNTApIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDAsMCkiIG9wYWNpdHk9IjEiIHN0eWxlPSJkaXNwbGF5OiBibG9jazsiPjxnIG9wYWNpdHk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDEsMCwwLDEsOCw4KSI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBmaWxsLW9wYWNpdHk9IjAiIHN0cm9rZT0iIzNkNzA3MiIgc3Ryb2tlLW9wYWNpdHk9IjEiIHN0cm9rZS13aWR0aD0iMiIgZD0iIE0tNiw2IEMtNiw2IDYsLTYgNiwtNiI+PC9wYXRoPjwvZz48ZyBvcGFjaXR5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDgsOCkiPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbC1vcGFjaXR5PSIwIiBzdHJva2U9IiMzZDcwNzIiIHN0cm9rZS1vcGFjaXR5PSIxIiBzdHJva2Utd2lkdGg9IjIiIGQ9IiBNLTYsLTYgQy02LC02IDYsNiA2LDYiPjwvcGF0aD48L2c+PC9nPjxnIHN0eWxlPSJkaXNwbGF5OiBub25lOyIgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwwLDApIiBvcGFjaXR5PSIxIj48ZyBvcGFjaXR5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDgsOCkiPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbC1vcGFjaXR5PSIwIiBzdHJva2U9InJnYigwLDAsMCkiIHN0cm9rZS1vcGFjaXR5PSIxIiBzdHJva2Utd2lkdGg9IjIiIGQ9IiBNLTYsNiBDLTYsNiA2LC02IDYsLTYiPjwvcGF0aD48L2c+PGcgb3BhY2l0eT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSw4LDgpIj48cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGZpbGwtb3BhY2l0eT0iMCIgc3Ryb2tlPSJyZ2IoMCwwLDApIiBzdHJva2Utb3BhY2l0eT0iMSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSIgTS02LC02IEMtNiwtNiA2LDYgNiw2Ij48L3BhdGg+PC9nPjwvZz48L2c+PC9zdmc+);
	}
	&:hover {
		&:after {
			opacity: 1;
		}
		&:before {
			opacity: 0;
		}
	}
}

.alertify .ajs-body {
    position: relative;
    z-index: 2;
}

.alertify .ajs-body .ajs-content {
	padding: 0;
}

/* rest button */
.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	min-height: 0;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
	text-align: inherit;
	outline: none;
	border-radius: 0;
	&:focus {
		border: none;
	}
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}


.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button.ajs-cancel {
	align-items: center;
	color: var(--color-text);
	display: inline-flex;
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-semi-bold);
	line-height: 1.1;
	text-decoration: none;
	margin-right: 30px;

	position: relative;
	text-decoration: none !important;
	cursor: pointer;
	&:first-of-type {
		 margin-right: 0;
	 }

	&::before {
		content: "";
		background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuOTk5NzUsNi42NTY4IEwxMS41ODU3NSw2LjY1NjggTDcuNjM1NzUsMi43MDY4IEM3LjI0NTc1LDIuMzE2OCA3LjI0NTc1LDEuNjgzOCA3LjYzNTc1LDEuMjkyOCBDOC4wMjY3NSwwLjkwMjggOC42NTk3NSwwLjkwMjggOS4wNDk3NSwxLjI5MjggTDE0LjcwNjc1LDYuOTQ5OCBDMTUuMDk3NzUsNy4zNDA4IDE1LjA5Nzc1LDcuOTcyOCAxNC43MDY3NSw4LjM2MzggTDkuMDQ5NzUsMTQuMDIwOCBDOC44NTQ3NSwxNC4yMTU4IDguNTk4NzUsMTQuMzEzOCA4LjM0Mjc1LDE0LjMxMzggQzguMDg2NzUsMTQuMzEzOCA3LjgzMTc1LDE0LjIxNTggNy42MzU3NSwxNC4wMjA4IEM3LjI0NTc1LDEzLjYyOTggNy4yNDU3NSwxMi45OTY4IDcuNjM1NzUsMTIuNjA2OCBMMTEuNTg1NzUsOC42NTY4IEwxLjk5OTc1LDguNjU2OCBDMS40NDc3NSw4LjY1NjggMC45OTk3NSw4LjIwODggMC45OTk3NSw3LjY1NjggQzAuOTk5NzUsNy4xMDQ4IDEuNDQ3NzUsNi42NTY4IDEuOTk5NzUsNi42NTY4IFoiIGZpbGw9IiMyNjFiMTQiPjwvcGF0aD48L3N2Zz4=);
		width: 16px;
		height: 16px;
		display: inline-block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		top: -2px;
		-webkit-transition: right .5s;
		-o-transition: right .5s;
		transition: right .5s;
		position: relative;
		 left: -2px;
		 right: auto;
		 margin-left: 0;
		 margin-right: 1rem;
		 transform: rotate(180deg);
	}

	&::after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		background-color: currentColor;
		width: 100%;
		height: 2px;
		transform: scaleX(0);
		transform-origin: bottom right;
		transition: transform 250ms ease-out;
	}

	&:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}

}

.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
	position: relative;
	z-index: 1;
	cursor: pointer;
	&.ajs-ok {
		 font-family: var(--font-family-slab);
		 font-weight: 500;
		 line-height: 1.1;
		 color: white;
		 margin-bottom: 0.1em;
		 font-size: 2.4rem;
		 text-transform: uppercase;
		&:before {
			content: "";
			background-image: url(https://www.fairtradeoriginal.nl/wp-content/themes/kaliber-fair-trade-original/assets/images/smudge.webp);
			background-repeat: no-repeat;
            background-size: contain;
            width: 500px;
            height: 324px;
			display: inline-block;
			position: absolute;
			top: -100px;
			left: -80px;
			z-index: -1;
			transform: rotate(-13deg);
			pointer-events: none;
		}
	}
}

.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button.ajs-ok .ButtonIcon-container {
	pointer-events: none;
	display: inline-block;
	width: 100%;
}

.alertify .ajs-footer {
	padding: 20px;
}

.alertify .ajs-footer .ajs-buttons {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	align-items: flex-end;
}