.Title {
  text-transform: uppercase;
  font-feature-settings: "kern" off, "liga" off;
  text-rendering: optimizeSpeed;
  transform: translateZ(0);
  display: block;
  width: 100%;
}

.Title-word {
  white-space: nowrap;
}

.Title-char {
  will-change: transform, opacity;
}
