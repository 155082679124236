.UpdatesItem {
  color: var(--color-text);
  display: block;
  margin-bottom: 30px;
  text-decoration: none;

  @media (--viewport-md) {
    margin-bottom: 60px;
  }
}

.UpdatesItem-layout {
  background-color: var(--color-white);
  border: solid 1px var(--color-gray-light);

  @media (--viewport-md) {
    display: flex;
  }
}

.UpdatesItem:nth-child(even) .UpdatesItem-layout {
  flex-direction: row-reverse;
}

.UpdatesItem-imageContainer,
.UpdatesItem-content {
  width: 100%;

  @media (--viewport-md) {
    width: 50%;
  }
}

.UpdatesItem-imageContainer {
  min-height: 175px;
  position: relative;
}

.UpdatesItem-image {
  background-color: var(--color-yellow);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.UpdatesItem-content {
  background-color: var(--color-white);
  padding: 30px 20px;

  @media (--viewport-md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    padding: 50px;
  }
}

.UpdatesItem-date {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: 1;
  margin-bottom: 10px;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.UpdatesItem-title {
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
  }
}

.UpdatesItem-author {
  border-top: solid 2px var(--color-black);
  display: none;
  margin-top: 20px;
  padding-top: 20px;
  width: 100%;

  @media (--viewport-md) {
    align-items: center;
    display: flex;
  }
}

.UpdatesItem-authorImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 55px;
  margin-right: 25px;
  width: 55px;
}

.UpdatesItem-authorName {
  color: var(--color-red);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-s);
}

.UpdatesItem-authorJobtitle {
  color: var(--color-text);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
}
