.AuthorSmall {
  align-items: center;
  display: flex;
}

.AuthorSmall-image {
  background-color: var(--color-red);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 55px;
  margin-right: 20px;
  width: 55px;
  flex-shrink: 0;
}

.AuthorSmall-nameContainer {
  font-size: var(--font-size-m);
}

.AuthorSmall-name {
  color: var(--color-red);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  margin-bottom: 4px;
}

.AuthorSmall-job {
  line-height: 1;
}
