.LinkBlockLarge {
  padding-bottom: 70px;
  overflow: hidden;
  max-width: 100vw;
  padding-top: 5px; /* for rotate image */

  @media (--viewport-md) {
    padding-bottom: 40px; /* for rotate image */
    padding-top: 40px; /* for rotate image */
  }

  @media (--viewport-lg) {
    padding-bottom: 60px; /* for rotate image */
    padding-top: 60px; /* for rotate image */
  }
}

.LinkBlockLarge-layout {
  @media (--viewport-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.LinkBlockLarge:not(.LinkBlockLarge--reverse):nth-child(even) .LinkBlockLarge-layout {
  flex-direction: row-reverse;
}

.LinkBlockLarge.LinkBlockLarge--reverse:nth-child(odd) .LinkBlockLarge-layout {
  flex-direction: row-reverse;
}

.LinkBlockLarge:not(.LinkBlockLarge--reverse):nth-child(odd) .LinkBlockLarge-image {
  right: 10%;
}

.LinkBlockLarge:not(.LinkBlockLarge--reverse):nth-child(even) .LinkBlockLarge-image {
  left: 10%;
}

.LinkBlockLarge.LinkBlockLarge--reverse:nth-child(even) .LinkBlockLarge-image {
  right: 10%;
}

.LinkBlockLarge.LinkBlockLarge--reverse:nth-child(odd) .LinkBlockLarge-image {
  left: 10%;
}

.LinkBlockLarge-imageContainer,
.LinkBlockLarge-content {
  @media (--viewport-md) {
    width: calc(50% - 25px);
  }

  @media (--viewport-lg) {
    width: calc(50% - 50px);
  }
}

.LinkBlockLarge-imageContainer {
  margin-bottom: 60px;
  position: relative;
  height: calc((100vw - (var(--gutter-h) * 2)) * (11 / 16));

  @media (--viewport-sm) {
    height: calc((100vw - (var(--gutter-h) * 4)) * (11 / 16));
  }

  @media (--viewport-md) {
    align-self: stretch;
    height: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
}

.LinkBlockLarge-image {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  @media (--viewport-md) {
    width: 150%;
    height: auto;
  }

  @media (--viewport-lg) {
    width: 130%;
  }

  @media (--viewport-xl) {
    width: 150%;
  }
}

.LinkBlockLarge:nth-child(4n+1) .LinkBlockLarge-image {
  transform: rotate(-2deg);
}

.LinkBlockLarge:nth-child(4n+2) .LinkBlockLarge-image {
  transform: rotate(0.5deg);
}

.LinkBlockLarge:nth-child(4n+3) .LinkBlockLarge-image {
  transform: rotate(-1deg);
}

.LinkBlockLarge:nth-child(4n+4) .LinkBlockLarge-image {
  transform: rotate(1.5deg);
}

.LinkBlockLarge-content {
  @media (--viewport-md) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (--viewport-lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (--viewport-xl) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.LinkBlockLarge-description,
.LinkBlockLarge-title {
  font-family: var(--font-family-slab);
  margin-top: 0;
}

.LinkBlockLarge-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
  margin-top: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

.LinkBlockLarge-titleHighlight {
  font-weight: var(--font-weight-medium);
}

.LinkBlockLarge-description {
  margin-bottom: 10px;
}

.LinkBlockLarge-link {
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-red);
  font-size: var(--font-size-m);

  & svg {
    fill: currentColor;
    margin-left: 10px;
  }
}
