.BackLink {
  align-items: center;
  color: var(--color-red);
  display: flex;
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  line-height: 1.1;
  text-decoration: none;
}

.BackLink svg {
  fill: var(--color-red);
  margin-right: 8px;
}
