.SiteLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 1 */ /* This does not work in IE11,  see below */
  position: relative;
}

.SiteLayout-main {
  flex: 1 0 auto; /* 2 */
  padding-top: var(--site-header-height);

  @media (--viewport-md) {
    padding-top: var(--site-header-height-md);
  }

  @media (--viewport-lg) {
    min-height: calc(100vh - var(--site-footer-height-lg)); /* Fix for IE11 */
  }
}

.SiteLayout-header,
.SiteLayout-footer {
  flex-shrink: 0; /* 2 */
}
