@import 'utilities/container';

.FrontPage {
  /* solves mysterious bug where drawer is not visible on home */
  position: relative;
  z-index: 1;
}

.FrontPage-payoffLink {
	font-family: var(--font-family-slab);
	font-size: 2.4rem;
	margin-left: auto;
	margin-right: auto;
	max-width: 650px;
	opacity: 0.8;
  color: var(--color-text);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;
  text-decoration: none;
  transition: color 200ms ease-in-out;
  will-change: opacity;

  &:hover,
  &:focus {
    color: var(--color-red);
  }

	@media (--viewport-md) {
    font-size: var(--font-size-l);
    max-width: 650px;
    padding-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (--viewport-xl) {
    padding-top: 50px;
  }
}

.FrontPage-heroContainer {
  @media (--viewport-md) {
    @apply --container-base;

    max-width: var(--container-width-xxl);
    padding-left: calc(var(--gutter-h) * 2);
    padding-right: calc(var(--gutter-h) * 2);
    margin-bottom: 160px;
  }
}

.FrontPage-hero {
  position: relative;
  margin-bottom: 40px;
  z-index: 2;

  @media (--viewport-md) {
    margin-bottom: 120px;
  }
}

.FrontPage-chapterLayout {
  position: relative;

  @media (--viewport-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.FrontPage-chapterInset {
  width: 160px;
  position: absolute;
  top: -80px;
  left: -80px;
  z-index: 1;
}

.FrontPage-farmers {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -50px;
  position: absolute;
  z-index: 1;

  @media (--viewport-sm) {
    justify-content: center;
    padding-left: 80px;
    margin-top: -80px;
  }

  @media (--viewport-md) {
    padding-left: 0;
    margin-top: 0;
    bottom: -40px;
    right: 10%;
  }

  @media (--viewport-lg) {
    position: absolute;
    bottom: 120px;
  }
}

.FrontPage-farmer {
  height: 98px;

  @media (--viewport-sm) {
    height: 120px;
  }
}

.FrontPage-farmer:nth-child(1) {
  position: relative;
  z-index: 1;
}

.FrontPage-farmer:nth-child(2) {
  transform: translate(-40px, -10px);
}

.FrontPage-topic {
  position: relative;
}

.FrontPage-topicContent {
  color: var(--color-text);
  text-decoration: none;

  @media (--viewport-md) {
    height: 100%;
  }
}

.FrontPage-topicFlexEmbed {
  transform: translate3d(0, 0, 0) rotate(0.5deg);
}

.FrontPage-topicFlexEmbed::after {
  padding-bottom: 68.71794872%;
}

.FrontPage-topicLink {
  display: block;
  background-color: var(--color-white);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.2);
  color: var(--color-text);
  font-family: var(--font-family-slab);
  line-height: var(--line-height-heading);
  position: relative;
  text-decoration: none;
  top: -10px;
  z-index: 1;
  padding: 30px 20px;
  max-width: 465px;
  will-change: transform;

  @media (--viewport-md) {
    bottom: -140px;
    left: 20px;
    padding: 30px 40px;
    position: absolute;
    top: auto;
    max-width: 365px;
  }

  @media (--viewport-lg) {
    bottom: -40px;
    left: 20px;
    padding: 30px 40px;
    position: absolute;
    top: auto;
    max-width: 365px;
  }

  &:hover,
  &:focus {
    /* animation: deRotate 400ms steps(4, end);
    animation-fill-mode: forwards; */
  }
}

.FrontPage-topicLabel {
  color: var(--color-red);
  font-size: var(--font-size-m);
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  margin-bottom: 5px;
}

.FrontPage-topicTtle {
  margin-bottom: 0;
  margin-top: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-xxxl);
  }
}

.FrontPage-topicDescription {
  margin-bottom: 0;
  margin-top: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.FrontPage-chapter--products {
  margin-bottom: 120px;

  @media (--viewport-md) {
    margin-bottom: 260px;
  }
}

.FrontPage-chapter--products .FrontPage-chapterLink {
  position: relative;
  flex-shrink: 0;
  top: 20px;

  @media (--viewport-sm) {
    width: 300px;
  }

  @media (--viewport-xl) {
    width: 400px;
    top: 80px;
  }
}

.FrontPage-chapter--products .FrontPage-extraShape {
  position: absolute;
  transform: scale(0.6);
  mix-blend-mode: multiply;
  width: 200px;
  z-index: -1;
  display: none;

  @media (--viewport-md) {
    display: block;
    right: -50px;
    bottom: -120px;
  }

  @media (--viewport-lg) {
    bottom: 140px;
    right: -100px;
  }
}

.FrontPage-chapter--products .FrontPage-chapterLinkContainer {
  position: relative;
  z-index: 1;

  @media (--viewport-md) {
    width: calc(50% - 25px);
    align-self: stretch;
  }

  @media (--viewport-lg) {
    width: calc(40% - 25px);
  }

  @media (--viewport-lg) {
    display: flex;
    justify-content: flex-end;
  }
}

.FrontPage-chapter--products .FrontPage-topic {
  margin-top: 20px;

  @media (--viewport-md) {
    margin-top: 0;
    align-self: stretch;
    width: calc(50% - 25px);
  }

  @media (--viewport-lg) {
    width: calc(60% - 25px);
  }
}

.FrontPage-chapter--products .FrontPage-topicLink {
  transform: translate3d(0, 0, 0) rotate(-2deg);
}

.FrontPage-chapter--recipes {
  margin-bottom: 80px;
}

.FrontPage-chapter--recipes .FrontPage-chapterLayout {
  flex-direction: row-reverse;
}

.FrontPage-chapter--recipes .FrontPage-chapterLink {
  position: relative;
  flex-shrink: 0;
  width: 300px;
  margin-bottom: -40px;

  @media (--viewport-sm) {
    width: 300px;
  }
}

.FrontPage-chapter--recipes .FrontPage-extraShape {
  position: absolute;
  transform: translate(140px, -200px) rotate(-28deg) scale(0.6);
  mix-blend-mode: multiply;

  @media (--viewport-sm) {
    transform: translate(180px, -200px) rotate(-28deg) scale(0.6);
  }

  @media (--viewport-md) {
    left: -300px;
    bottom: -360px;
  }
}

.FrontPage-chapter--recipes .FrontPage-chapterLinkContainer {
  @media (--viewport-md) {
    width: calc(40% - 25px);
    align-self: stretch;
  }
}

.FrontPage-chapter--recipes .FrontPage-topic {
  margin-top: 20px;

  @media (--viewport-md) {
    margin-top: 0;
    align-self: stretch;
    width: calc(60% - 25px);
  }

  @media (--viewport-lg) {
    width: calc(60% - 25px);
  }
}

.FrontPage-chapter--recipes .FrontPage-chapterInset {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 120px;
  top: auto;
  left: auto;
  z-index: 2;
  pointer-events: none;

  @media (--viewport-sm) {
    width: 160px;
    bottom: -40px;
    right: -20px;
  }

  @media (--viewport-md) {
    width: 160px;
    bottom: 80%;
    right: 33%;
  }

  @media (--viewport-lg) {
    width: 200px;
    bottom: 5%;
    right: 40%;
  }
}

.FrontPage-chapter--recipes .FrontPage-topicLink {
  transform: translate3d(0, 0, 0) rotate(1.5deg);
}

.FrontPage-chapter--recipes .FrontPage-topicFlexEmbed {
  transform: translate3d(0, 0, 0) rotate(-1deg);

  @media (--viewport-md) {
    width: 130%;
    transform: translate(-30%, -60px);
  }

  @media (--viewport-lg) {
    width: 100%;
    transform: translate(-10%, -60px);
  }
}

.FrontPage-chapter--updates {
  margin-bottom: 80px;

  @media (--viewport-md) {
    margin-top: 200px;
    margin-bottom: 170px;
  }
}

.FrontPage-chapter--updates .FrontPage-topic {
  position: relative;
  z-index: 2;
}

.FrontPage-chapter--updates .FrontPage-chapterLayout {
  align-items: stretch;
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    flex-direction: column;
  }
}

.FrontPage-chapter--updates .FrontPage-chapterLayout .FrontPage-chapterLinkContainer {
  position: relative;
  top: 30px;

  @media (--viewport-sm) {
    margin-left: auto;
    margin-right: 200px;
  }

  @media (--viewport-md) {
    top: 0;
    margin-top: 60px;
    margin-right: 0;
    margin-left: 0;
    z-index: 1;
  }

  @media (--viewport-lg) {
    margin-top: 120px;
  }
}

.FrontPage-chapter--updates .FrontPage-chapterLink {
  position: relative;
  flex-shrink: 0;
  width: 300px;

  @media (--viewport-md) {
    margin-top: -80px;
  }

  @media (--viewport-lg) {
    width: 320px;
    position: relative;
    top: -40px;
    left: 180px;
  }
}

.FrontPage-chapter--updates .FrontPage-extraShape {
  display: none;

  @media (--viewport-md) {
    position: absolute;
    right: 50px;
    top: -200px;
    display: block;
    z-index: 2;
    width: 200px;
  }
}

.FrontPage-chapter--updates .FrontPage-topicFlexEmbed {
  margin: -6px;
  transform: translate3d(0, 0, 0) rotate(0.5deg);

  &::after {
    padding-bottom: 56.5%;

    @media (--viewport-lg) {
      padding-bottom: 39.0625%;
    }
  }
}

.FrontPage-chapter--updates .FrontPage-topicLink {
  transform: translate3d(0, 0, 0) rotate(-1deg);
  left: 20px;
  width: calc(100% - 20px);
  max-width: 370px;

  @media (--viewport-md) {
    left: auto;
    bottom: -40px;
    right: 20px;
  }
}

.FrontPage-chapter--about {
  margin-bottom: 80px;
  min-height: 260px;

  @media (--viewport-md) {
    margin-bottom: 20px;
  }
}

.FrontPage-chapter--about .FrontPage-chapterLayout {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.FrontPage-chapter--about .FrontPage-chapterLink {
  width: 260px;
  position: absolute;
  right: 20%;

  @media (--viewport-md) {
    margin-top: -80px;
  }

  @media (--viewport-xl) {
    width: 300px;
  }
}

.FrontPage-aboutUsSupercharger {
  margin-bottom: 80px;
}
