.FrontPageHeroLink {
  font-family: var(--font-family-slab);
  line-height: var(--line-height-heading);
  position: relative;
  padding: 30px 20px 0;

  @media (--viewport-md) {
    padding: 30px 40px 0;
    max-width: 600px;
  }
}

.FrontPageHeroLink-label {
  color: var(--color-red);
  font-size: var(--font-size-m);
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
}

.FrontPageHeroLink-title {
  margin-bottom: 0;
  margin-top: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-xxxl);
  }
}

.FrontPageHeroLink-description {
  margin-bottom: 0;
  margin-top: 0;
  line-height: var(--line-height-m);

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.FrontPageHeroLink-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--color-marine-blue);
  color: var(--color-white);
  transform: translateY(50%);
}
