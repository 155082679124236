:root {
  --container-base: {
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--gutter-h);
    padding-right: var(--gutter-h);
    width: 100%;

    @media (--viewport-sm) {
      padding-left: calc(var(--gutter-h) * 2);
      padding-right: calc(var(--gutter-h) * 2);
    }
  };
}

.u-container-sm {
  @apply --container-base;

  max-width: var(--container-width-sm);
}

.u-container-md {
  @apply --container-base;

  max-width: var(--container-width-md);
}

.u-container-lg {
  @apply --container-base;

  max-width: var(--container-width-lg);
}

.u-container-xl {
  @apply --container-base;

  max-width: var(--container-width-xl);
}

.u-container-xxl {
  @apply --container-base;

  max-width: var(--container-width-xxl);
}
