.Pagination {
  display: flex;
  font-size: var(--font-size-m);
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  align-items: stretch;
}

.Pagination-numLinks .page-numbers {
  width: 38px;
}

.Pagination-textLink--prev a {
  padding-left: 28px;
  background-image: url(../images/arrow-left.svg);
  background-position: left 10px;
  background-repeat: no-repeat;
}

.Pagination-textLink--next a {
  padding-right: 28px;
  background-image: url(../images/arrow-right.svg);
  background-position: right 10px;
  background-repeat: no-repeat;
}

.Pagination-textLink {
  min-width: 100px;
}

.Pagination-numLinks .page-numbers,
.Pagination-textLink > a {
  height: 38px;
  color: var(--color-black);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 200ms ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-red);
  }
}

.Pagination-numLinks .page-numbers.current {
  background-image: url(../images/pagination-active-indicator.png);
  background-position: center;
  background-repeat: no-repeat;
}

.Pagination-numLinks {
  display: none;

  @media (--viewport-md) {
    display: flex;
  }
}
