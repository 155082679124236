.TagNav {
  border-bottom: 2px solid var(--color-black);
}

.TagNav-layout {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 56px;
  overflow: hidden;

  @media (--viewport-md) {
    min-height: 40px;
  }
}

.TagNav-label {
  text-transform: uppercase;
  font-family: var(--font-family-slab);
}

.TagNav-label,
.TagNav-select {
  font-size: var(--font-size-m);

  @media (--viewport-md) {
    display: none;
  }
}

.TagNav-select {
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-slab);
  text-transform: uppercase;

  & option {
    text-transform: initial;
  }
}

.TagNav-listLayout {
  display: none;

  @media (--viewport-md) {
    align-items: stretch;
    display: flex;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: transform 200ms ease-in-out;
    width: 200%;

    &.is-open {
      transform: translateX(-50%);
    }
  }
}

.TagNav-listShow {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  flex-shrink: 0;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  outline: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 50%;
}

.TagNav-listContainer {
  flex-shrink: 0;
  width: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
}

.TagNav-list {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.TagNav-link {
  color: var(--color-text);
  font-size: var(--font-size-m);
  line-height: 1.3;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  white-space: nowrap;
}

.TagNav-listHide {
  position: absolute;
  right: 0;
  top: -4px; /* height of button is 48px, height of element os 40px */
}

.TagNav-listShow,
.TagNav-link,
.TagNav-listHide {
  outline: none;
  transition: color 200ms ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-greenish-blue);
  }
}

.TagNav-link--active {
  font-weight: var(--font-weight-semi-bold);
}
