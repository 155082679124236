.Drawer {
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-drawer);
  display: none;
}

.Drawer-circle {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 43px;
  right: 43px;
  transform: translate(50%, -50%);
  background-color: var(--color-white);
  border-radius: 50%;
}

.Drawer-scrollContainer {
  height: 100vh;
  background-color: var(--color-white);
  color: var(--color-text);
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.Drawer-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%; /* this does not work in IE11, so there is a fix in .DrawerMenu */
}

.Drawer-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
}

.Drawer-menu {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
  flex-grow: 1;
}

.Drawer-footer {
  width: 100%;
  margin-top: auto;
}
