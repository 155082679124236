.SingleRecipe-pageHeader {
  margin-bottom: 10px;
  margin-top: 50px;

  @media (--viewport-md) {
    margin-bottom: 15px;
    margin-top: 80px;
  }
}

.SingleRecipe-meta {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.SingleRecipe-heroContainer {
  margin-bottom: 30px;

  @media (--viewport-md) {
    @apply --container-base;

    margin-bottom: 50px;
    max-width: var(--container-width-xxl);
    padding-left: calc(var(--gutter-h) * 2);
    padding-right: calc(var(--gutter-h) * 2);
  }
}

.SingleRecipe-hero {
  position: relative;
}

.SingleRecipe-farmer {
  bottom: 0;
  height: 100px;
  position: absolute;
  right: 0;
  transform: translate(30px, 20px);
  width: 100px;
  z-index: 1;

  @media (--viewport-sm) {
    transform: translate(10px, 20px);
  }

  @media (--viewport-md) {
    width: 160px;
    height: 160px;
    transform: translate(40px, 30px);
  }

  @media print { display: none; }
}

.SingleRecipe-description {
  color: var(--color-text-light);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin-bottom: 30px;
  text-align: center;
  z-index: 1;
  position: relative;

  @media (--viewport-md) {
    font-size: var(--font-size-l);
    margin-bottom: 80px;
  }
}

.SingleRecipe-onPageLinks {
  margin-bottom: 20px;

  @media (--viewport-md) {
    display: none;
  }

  @media print { display: none; }
}

.SingleRecipe-recipe {
  margin-bottom: 60px;

  @media (--viewport-md) {
    margin-bottom: 60px;
  }
}

.additional-seo-text {
	border-top: 1px solid #261b14;
	margin-top: 3rem;
	padding-top: 2rem;
}

.SingleRecipe-recipeContainer {
  @media (--viewport-md) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.SingleRecipe-ingredients,
.SingleRecipe-preparation {
  width: 100%;

  @media (--viewport-md) {
    width: calc(50% - 20px);
  }
}

.SingleRecipe-ingredients {
  margin-bottom: 35px;

  @media (--viewport-md) {
    margin-bottom: 0;
  }
}

.Recipe-video {
  margin-top: 80px;
  margin-bottom: 80px;
}

.SingleRecipe-share {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 75px;
  }

  @media print { display: none; }
}

.SingleRecipe-newsletter {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }

  @media print { display: none; }
}

.SingleRecipe-related {
  margin-bottom: 90px;

  @media (--viewport-md) {
    margin-bottom: 65px;
  }

  @media print { display: none; }
}

.SingleRecipe-aboutUs {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }

  @media print { display: none; }
}
