.ExternalRecipeGridItem {
  text-decoration: none;
  color: currentColor;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  display: block;
  width: 100%;
}

.ExternalRecipeGridItem-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ExternalRecipeGridItem-image {
  background-size: cover;
  background-position: center;
}

.ExternalRecipeGridItem-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 25px;
}

.ExternalRecipeGridItem-title {
  flex-grow: 1;
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin-bottom: 20px;
  text-transform: uppercase;
  max-width: 100%; /* IE11 */
}

.ExternalRecipeGridItem-imageContainer {
  position: relative;
}

.ExternalRecipeGridItem-source {
  align-items: center;
  color: var(--color-red);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semi-bold);
}

.ExternalRecipeGridItem-source svg {
  fill: var(--color-red);
  height: auto;
  margin-left: 10px;
  width: 20px;
}
