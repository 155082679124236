.RecipesOverview-header {
  margin-bottom: 30px;
  margin-top: 50px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 60px;
  }
}

.RecipesOverview-termNav {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.RecipesOverview-pagination {
  margin-bottom: 20px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.RecipesOverview-newsletter {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}

.RecipesOverview-aboutUsSupercharger {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}
.is--flex {
  display: flex;
}

.col-sidebar-left {
  flex-basis: 33%;
  width: 33%;
  display: inline-block;

	@media (--viewport-lg) {
		flex-basis: 20%;
  	width: 20%;
		padding-top: 2rem;
	}
}

.col-sidebar-left.sidebar-open {
	opacity: 1;
	visibility: visible;
}

.content-sidebar {
  flex-basis: 67%;
  width: 67%;
  display: inline-block;

	@media (--viewport-lg) {
		flex-basis: 80%;
  	width: 80%;
	}
}

.sidebar-link,
.sidebar-link:visited{
  text-decoration: none;
  color: inherit
}

.sidebar-link.active {
  color: var(--color-red);
}

.misha_loadmore {
  margin-bottom: 3rem;
}

.btn-reset {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  border: 0;
  text-align: inherit;
  outline: none;
}
.Recipes-filter-toggle {
  display: none;
}
.Recipes-filter-toggle-on {
  padding: 0 20px;
}
.Recipes-filter-toggle svg {
  pointer-events: none;
  display: inline-block;
  float: left;
  width: 20px;
  margin-right: .5rem;
}

.Recipes-filter-toggle span {
  display: inline-block;
  float: left;
  pointer-events: none;
}

/*sidebar*/


/* ------------- */
@media (max-width: 48em) {
  .Recipes-filter-toggle {
    display: inline-block;
  }
  .col-sidebar-left {
    background-color: #fff;
    color: #333;
    height: 100%;
    width: 90vw;
    position: fixed;
    text-align: left;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    top: 0;
    left: 0;
    padding: 6rem 3rem 3rem;
    overflow-y: scroll;
  }
  .col-sidebar-left.is--active {
    visibility: visible;
    opacity: 1;
  }
  .content-sidebar {
    flex-basis: 100%;
    width: 100%;
  }
}
.u-container-xl .col-sidebar-left.is--open {
  opacity: 1;
  visibility: visible;
}

/*isotope*/
/* .content-sidebar .Grid {
  display: block;
  width: 100%;
} */


.content-sidebar .Grid .Grid-item {
  width: 100% !important;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  will-change: auto;

  /* @media (--viewport-md) {
    width: 31% !important;
  } */
}
/* .content-sidebar .Grid .Grid-item:not([style*="left"]) {
  opacity: 0;
} */

.content-sidebar .Grid .Grid-sizer {
  width: 100%;

  @media (--viewport-md) {
    width: 31%;
  }
}

.Grid-gutter {
  width: 3%;
}

.Grid-item .GridFilterTags {
  display: none;
}

/*!*isotope*!*/
/*.content-sidebar .Grid .Grid-sizer,*/
/*.content-sidebar .Grid .Grid-item {*/
/*  width: 33% !important;*/
/*}*/



/* Hide the browser's default checkbox */
.filter-tags input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filter-tags label {
  position: relative;
  padding-left: 2.5rem;
  font-size: 1.8rem;
}

label.filter-item-label.is--empty {
  opacity: .5;
  pointer-events: none;
}

.filter-tags .filter-count,
.filter-tags .facetwp-counter {
  display: none;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.filter-tags input:checked ~ .checkmark {
  background-color: #e73c31;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.filter-tags input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.filter-tags .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.RecipesOverview-grid .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* FacetWP styling */
.filter-tags .facetwp-checkbox {
	background: none;
	padding-left: 2.5rem;
	position: relative;
}

.filter-tags .facetwp-checkbox.checked {
	background-image: none;
}

.filter-tags .facetwp-checkbox.checked::before {
	background-color: var(--color-red);
}

.filter-tags .facetwp-checkbox.checked::after {
	opacity: 1;
}

.filter-tags .facetwp-checkbox::before {
	content: "";
	background: #eee;
	width: 2rem;
	height: 2rem;
	position: absolute;
	left: 0;
	top: 3px;

	@media (--viewport-md) {
		top: 10px;
	}
}

.filter-tags .facetwp-checkbox::after {
	content: "";
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M493.3 128l-22.6 22.6-256 256L192 429.3l-22.6-22.6-128-128L18.7 256 64 210.7l22.6 22.6L192 338.7 425.4 105.4 448 82.7 493.3 128z' fill='%23FFF'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 1rem;
	background-position: center center;
	opacity: 0;
	width: 2rem;
	height: 2rem;
	position: absolute;
	left: 0;
	top: 3px;

	@media (--viewport-md) {
		top: 10px;
	}
}

.filter-tags .facetwp-display-value {
  position: relative;
  font-size: 1.8rem;
}

.loadmore-button {
	margin-top: 2rem;
	text-align: center;
}

.facetwp-load-more,
.Recipes-filter-toggle-on {
	background-color: #e73c31;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Ciutadella Slab', Georgia, Times, 'Times New Roman', serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  height: 48px;
  line-height: 1;
  outline: none;
	padding-inline: 2rem;
  position: relative;
  -webkit-transition: background-color 200ms ease-out;
  transition: background-color 200ms ease-out;
}

.Recipes-filter-toggle-on {
	margin-bottom: 2rem;
}

.facetwp-load-more:hover,
.facetwp-load-more:focus {
	background-color: #adcccc;
}
