.SingleProduct-intro {
  padding-top: 50px;
  padding-bottom: 70px;
  position: relative;
  z-index: 1;

  @media (--viewport-md) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

.SingleProduct-shapes {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.SingleProduct-shape {
  position: absolute;
  mix-blend-mode: multiply;
}

.SingleProduct-shape--golfClub {
  top: -70px;
  right: -220px;
  width: 744px; /* inherent width of shape */
  transform: rotate(-73deg) scale(0.6);

  @media (--viewport-md) {
    top: -10px;
    right: -10px;
    transform: rotate(-73deg) scale(1.2);
  }
}

.SingleProduct-shape--blob {
  top: 360px;
  right: -40px;
  transform: scale(0.6);
  width: 277px; /* inherent width of shape */

  @media (--viewport-md) {
    top: 500px;
    right: 130px;
    transform: scale(1);
  }
}

.SingleProduct-shape--smudge {
  bottom: -150px;
  left: -260px;
  transform: rotate(65deg) scale(0.6);
  width: 611px;

  @media (--viewport-md) {
    bottom: -100px;
    left: -260px;
    transform: rotate(65deg) scale(1);
  }
}

.SingleProduct-shape--droplet {
  top: 60px;
  left: -60px;
  transform: rotate(121deg) scale(0.6);
  width: 400px;

  @media (--viewport-md) {
    top: 120px;
    left: -60px;
    transform: rotate(121deg) scale(1);
  }
}

.SingleProduct-timeline {
  margin-bottom: 30px;

  @media (--viewport-md) {
    margin-bottom: 90px;
  }
}

.SingleProduct-recipe {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 140px;
  }
}

.SingleProduct-stores {
  margin-bottom: 60px;

  @media (--viewport-md) {
    margin-bottom: 120px;
  }
}

.SingleProduct-contents,
.SingleProduct-extraInformation {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-top: 70px;
  }
}

.SingleProduct-newsletter {
  margin-bottom: 40px;
  margin-top: 70px;

  @media (--viewport-md) {
    margin-bottom: 80px;
    margin-top: 100px;
  }
}

.SingleProduct-related {
  margin-bottom: 90px;

  @media (--viewport-md) {
    margin-bottom: 65px;
  }
}

.SingleProduct-aboutUs {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}
