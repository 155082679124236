.OnPageLinks {
  border-bottom: solid 2px var(--color-black);
  border-top: solid 2px var(--color-black);
  padding-bottom: 15px;
  padding-top: 15px;

  @media (--viewport-md) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.OnPageLinks-link {
  align-items: center;
  color: var(--color-text);
  display: flex;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: 1.1;
  text-transform: uppercase;
  text-decoration: none;

  &:not(:last-child) {
     margin-bottom: 14px;

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }
}

.OnPageLinks-arrow {
  fill: var(--color-black);
  height: 16px;
  margin-right: 12px;
  width: 16px;

  @media (--viewport-md) {
    display: none;
  }
}



.ProductIntro-column .OnPageLinks {
  padding: 10px;

  @media (--viewport-md) {
    flex-wrap: wrap;
    align-items: start;
  }
}

.ProductIntro-column .OnPageLinks-link {
  padding: 10px;

  @media (--viewport-md) {
    /*flex: 0 0 50%;*/
    max-width: 50%;
  }

}

