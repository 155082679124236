.DefaultImage {
  height: auto;
  margin: 60px 0;
  width: 100%;
  transform: rotate(1deg);
}

.DefaultImage-image {
  height: auto;
  width: 100%;
}

.DefaultImage-caption {
  font-size: var(--font-size-m);

  @media (--viewport-md) {
    margin-top: 10px;
    margin-left: 20px;
  }
}
