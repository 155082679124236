.CTAMultiline {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 35px;
  text-decoration: none;
  width: 100%;

  @media (--viewport-md) {
    align-items: center;
    flex-direction: row;
  }
}

.CTAMultiline-group {
  @media (--viewport-md) {
    align-items: center;
    display: flex;
    max-width: 70%;
  }
}

.CTAMultiline-image {
  background-color: var(--color-gray-light);
  border-radius: 50%;
  flex-shrink: 0;
  height: 85px;
  margin-bottom: 10px;
  width: 85px;

  @media (--viewport-md) {
    margin-right: 30px;
  }
}

.CTAMultiline-text {
  color: var(--color-black);
  display: block;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  margin-bottom: 10px;

  @media (--viewport-md) {
    margin-bottom: 0;
  }
}

.CTAMultiline-link {
  align-items: center;
  background-color: var(--color-red);
  border-radius: 100px;
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  margin-left: auto; /* IE11 fix */
  padding: 8px 20px;
  transition: background-color 200ms ease-out;

  &:hover, &:focus {
    background-color: var(--color-blue);
  }
}

.CTAMultiline-linkText {
  margin-right: 20px;
}

.CTAMultiline-link svg {
  fill: var(--color-white);
}
