.ContactAddress {
  font-size: var(--font-size-m);
}

.ContactAddress-title {
  margin-bottom: 10px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.ContactAddress-contentBlock {
  margin-bottom: 20px;
}

.ContactAddress-socialFollowContainer {
  margin-bottom: 20px;
  margin-left: -15px;

  @media (--viewport-md) {
    margin-left: 0;
  }
}

.ContactAddress-link {
  color: var(--color-red);
  font-weight: var(--font-weight-semi-bold);
}
