.ShareBlock-title {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  margin-bottom: 10px;

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

.ShareBlock-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewport-sm) {
    justify-content: flex-start;
  }
}

.ShareBlock-button,
.ShareBlock-mobile {
  margin-bottom: 6px;
  width: calc(50% - 8px);

  @media (--viewport-sm) {
    width: auto;
    margin-bottom: 0;
    margin-right: 12px;
    min-width: 48px;
  }
}

.ShareBlock-mobile {
  display: block;

  @media (--viewport-md) {
    display: none;
  }
}

.ShareBlock-mobile > .ShareBlock-button {
  width: 100%;
}

.ShareBlock-desktop {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}
