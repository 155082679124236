.UpdatesItemHeroContent {
  padding: 30px 20px 50px;

  @media (--viewport-md) {
    padding: 30px 40px;
    max-width: 800px;
  }
}

.UpdatesItemHeroContent-date {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: 1;
  margin-bottom: 10px;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.UpdatesItemHeroContent-title {
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-l);

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

.UpdatesItemHeroContent-author {
  align-items: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.UpdatesItemHeroContent-authorImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 55px;
  margin-right: 25px;
  width: 55px;
}

.UpdatesItemHeroContent-authorName {
  color: var(--color-red);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-s);
}

.UpdatesItemHeroContent-authorJobtitle {
  color: var(--color-text);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
}
