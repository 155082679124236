/* Top categories */

.u-container-fluid {
  padding: 0 25px 20px;

  @media (--viewport-md) {
    padding: 0 45px 20px;
  }
}

/* grid builder */
/* https://grid.layoutit.com/ */
.grid-container {
  display: grid;
  gap: .25em .25em;
  /*mobile*/
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 14rem 14rem 14rem 14rem 14rem;
  grid-template-areas:
    "grid-1 grid-1 grid-3 grid-3"
    "grid-2 grid-2 grid-3 grid-3"
    "grid-4 grid-4 grid-4 grid-4"
    "grid-5 grid-5 grid-6 grid-6"
    "grid-5 grid-5 grid-7 grid-7";

    /*tablet */
  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 18rem 18rem 18rem 18rem;
    grid-template-areas:
  "grid-1 grid-1 grid-4 grid-4"
  "grid-2 grid-3 grid-4 grid-4"
  "grid-5 grid-5 grid-6 grid-6"
  "grid-5 grid-5 grid-7 grid-7";
  }

  /*desktop*/
  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 23rem 23rem;
    grid-template-areas:
  "grid-1 grid-1 grid-4 grid-4 grid-5 grid-6"
  "grid-2 grid-3 grid-4 grid-4 grid-5 grid-7";
  }
}

.grid-1 { grid-area: grid-1; }
.grid-2 { grid-area: grid-2; }
.grid-3 { grid-area: grid-3; }
.grid-4 { grid-area: grid-4; }
.grid-5 { grid-area: grid-5; }
.grid-6 { grid-area: grid-6; }
.grid-7 { grid-area: grid-7; }

.RecipesHighlight-item {
  /*min-height: 16rem;*/
  grid-auto-rows: minmax(min-content, max-content);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
}

.RecipesHighlight-item .RecipeGridItem-imageContainer {
  position: relative;
  height: 100%;
}

.RecipesHighlight-item .RecipeGridItem {
  height: 100%;
  border: 0;
}
.RecipesHighlight-item .RecipeGridItem:hover {
  z-index: 2;
}
.RecipesHighlight-item .FlexEmbed.FlexEmbed--showOverflow {
  height: 100%;
}
.RecipesHighlight-item .HoverShow {
  margin-bottom: 0;
}

/* Grid */
h2.RecipesOverview-title {
  text-align: center;
  margin-bottom: 3rem;
}

.HoverShow-grid {
  margin-bottom: 6rem;
}
