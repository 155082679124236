#home-menu-blocks {
    max-width: 830px;
    margin: 0 auto;
}

.FrontPage-chapterLink .DrawerMenu-chapterLink {
    width: 100%;
    margin-top: 0;
}

#home-menu-blocks .DrawerMenu-chapterLink--products {
    @media (--viewport-hor) {
        width: 53%;
    }
}

#home-menu-blocks .DrawerMenu-chapterLink--recipes {

    @media (--viewport-md) {
        margin-top: -50px;
    }

    @media (--viewport-hor) {
        width: 45%;
        margin-top: 0;
    }
}

#home-menu-blocks .DrawerMenu-chapterLink--updates {

    @media (--viewport-hor) {
        width: 39%;
    }
}

#home-menu-blocks .DrawerMenu-chapterLink--about {

    @media (--viewport-md) {
        width: 390px;
    }

    @media (--viewport-hor) {
        width: 45%;
        margin-left: auto;
        margin-top: 50px;
        margin-bottom: 100px;
    }
}