.HoverShow {
  position: relative;
  overflow: hidden;
  margin-bottom: 5rem;
}

.HoverShow-overlay {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-end;
  position: absolute;
  width: 101%;
  bottom: -.1rem;
  transition: transform 300ms;
  /*transform: translate3d(0, calc(100% - 6.5rem), 0);*/
  padding: 20px 25px;
  will-change: transform;
}

.HoverShow-body {
  padding: 0;
  opacity: 0;
  transition: all 500ms 100ms;
  will-change: opacity, max-height;
  max-height: 0;
}

.HoverShow-body p {
  font-size: 1.8rem;
  margin: 10px 0 0 0;
}

.HoverShow:hover .HoverShow-overlay,
.HoverShow:focus .HoverShow-overlay {
  transform: translate3d(0, 0, 0);
}

.HoverShow:hover .RecipeGridItem-title,
.HoverShow:focus .RecipeGridItem-title {
  transform: translate3d(0, 0, 0);
}

.HoverShow:hover .HoverShow-body,
.HoverShow:focus .HoverShow-body {
  opacity: 1;
  max-height: 20rem;
}

.HoverShow .RecipeGridItem-title {
  margin: 0;
  display: block;
  /*transform: translate3d(0, -100%, 0);*/
  transition: transform 300ms;
}

