.LinkBlockSmall-layout {
  background-color: var(--color-white);
  border: solid 1px var(--color-gray-light);
  display: block;
  height: 100%;
  text-decoration: none;
  color: var(--color-text);

  @media (--viewport-md) {
    display: flex;
  }
}

.LinkBlockSmall-layout--right {
  @media (--viewport-md) {
    flex-direction: row-reverse;
  }
}

.LinkBlockSmall-image,
.LinkBlockSmall-content {
  width: 100%;

  @media (--viewport-md) {
    width: 50%;
  }
}

.LinkBlockSmall-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 200px;

  @media (--viewport-md) {
    height: auto;
    align-self: stretch;
  }
}

.LinkBlockSmall-content {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (--viewport-md) {
    padding: 50px 40px;
  }
}

.LinkBlockSmall-title {
  max-width: 100%; /* IE 11 */
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  line-height: var(--line-height-heading);
  margin-top: 0;
  padding-bottom: 6px;
  margin-bottom: auto;

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
    padding-bottom: 18px;
  }
}

.LinkBlockSmall-description {
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 100%; /* IE 11 */

  @media (--viewport-md) {
    font-size: var(--font-size-m);
    margin-bottom: 12px;
  }
}

.LinkBlockSmall-link {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-m);
  color: var(--color-red);
  line-height: 1.1;
}

.LinkBlockSmall-link svg {
  fill: var(--color-red);
  margin-left: 4px;
}
