::-moz-selection {
  background-color: var(--color-blue);
  background-image: url(../images/shape-texture.png);
  color: var(--color-white);
}

::selection {
  background-color: var(--color-blue);
  background-image: url(../images/shape-texture.png);
  color: var(--color-white);
}

:root {
  /* Fonts */
  --font-family-base: 'Ciutadella', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-family-slab: 'Ciutadella Slab', Georgia, Times, 'Times New Roman', serif;
  --font-family-alt: 'Americane', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  /* Sizes */
  --font-size-xxs: 1rem;
  --font-size-xs: 1.4rem;
  --font-size-s: 1.6rem;
  --font-size-m: 1.8rem;
  --font-size-l: 2.4rem;
  --font-size-xl: 3.2rem;
  --font-size-xxl: 4.5rem;
  --font-size-xxxl: 6.2rem;

  /* only h1 */
  --font-size-h1-sm: 3.6rem;
  --font-size-h1: 8.2rem;

  /* Leading */
  --line-height-heading: 1.1;
  --line-height-s: 1.25;
  --line-height-m: 1.6;
  --line-height-l: 1.8;

  /* Weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
}

h1 {
  font-family: var(--font-family-alt);
  font-size: var(--font-size-h1-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-heading);
  margin-bottom: 0.1em;
  margin-top: 0.25em;

  @media (--viewport-sm) {
    font-size: var(--font-size-xxl);
  }

  @media (--viewport-md) {
    font-size: var(--font-size-h1);
  }
}

h2 {
  color: var(--color-text);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-heading);
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  text-transform: uppercase;

  @media (--viewport-md) {
    font-size: var(--font-size-xxl);
  }
}

h3 {
  color: currentColor;
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-heading);
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  text-transform: uppercase;

  @media (--viewport-md) {
    font-size: var(--font-size-l);
  }
}

h4 {
  color: var(--color-text);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-s);
  margin-bottom: 0.1em;
  margin-top: 0.3em;
}

body {
  color: var(--color-text);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-family: var(--font-family-base);

  @media (--viewport-md) {
    font-size: var(--font-size-l);
  }

  /*
    kerning: on or off
    ligatures: on or off
    discretionary-ligatures: on or off
    old-style-figures: on or off
    lining-figures: on or off
    alternate glyphs: on or off
  */
  font-feature-settings: "kern" on, "liga" on, "dlig" off, "onum" off, "lnum" on, "ss01" off;
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--font-family-base);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

i,
em {
  font-style: italic;
}

@font-face {
  font-family: 'Ciutadella';
  src:
    url('../fonts/35A41C_2_0.woff2') format('woff2'),
    url('../fonts/35A41C_2_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ciutadella';
  src:
    url('../fonts/35A41C_1_0.woff2') format('woff2'),
    url('../fonts/35A41C_1_0.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ciutadella';
  src:
    url('../fonts/35A41C_0_0.woff2') format('woff2'),
    url('../fonts/35A41C_0_0.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Ciutadella Slab';
  src:
    url('../fonts/35A41C_5_0.woff2') format('woff2'),
    url('../fonts/35A41C_5_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ciutadella Slab';
  src:
    url('../fonts/35A41C_4_0.woff2') format('woff2'),
    url('../fonts/35A41C_4_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Americane';
  src:
    url('../fonts/35A41C_3_0.woff2') format('woff2'),
    url('../fonts/35A41C_3_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
