.SearchResults-result {
  background: var(--color-white);
  border: 1px solid var(--color-gray-light);
  color: var(--color-text);
  display: block;
  margin-bottom: 20px;
  padding: 15px;
  text-decoration: none;

  @media (--viewport-md) {
    padding: 45px;
  }
}

.SearchResults-resultType {
  color: var(--color-red);
  font-family: var(--font-family-slab);
  font-size: var(--font-size-s);
  line-height: 1;
  margin-bottom: 12px;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.SearchResults-title {
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewport-md) {
    font-size: var(--font-size-xl);
  }
}

.SearchResults-description {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}
