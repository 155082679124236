.ShapesBg {
  left: 0;
  max-height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  transform: scaleY(-1);

  @media (--viewport-md) {
    transform: scaleY(1);
  }

  @media print { display: none; }
}
