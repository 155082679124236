.Ingredients-title {
  margin-bottom: 20px;
}

.Ingredients-list {
  font-size: var(--font-size-s);
  margin-bottom: 30px;
  margin-top: 0;
  padding-left: 20px;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.Ingredients-listItem,
.Ingredients-linkListItem {
  margin-bottom: 8px;
}

.Ingredients-subtitle {
  margin-bottom: 20px;
}

.Ingredients-linkList {
  font-size: var(--font-size-s);
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 20px;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}

.Ingredients-linkListItem {
  list-style-type: none;
  counter-increment: list;
  position: relative;

  &::after {
    content: '';
    background-color: var(--color-red);
    border-radius: 50%;
    height: 5px;
    left: -20px;
    position: absolute;
    width: 5px;
    top: 10px;
  }
}
.Ingredients-info .WpContent li::before {
  width: 5px;
  height: 5px;
  left: -20px;
  top: 10px;
}
.Ingredients-link {
  color: var(--color-red);
  font-weight: var(--font-weight-semi-bold);
  text-decoration: none;
  display: inline-block;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

.Ingredients-info {
  font-size: var(--font-size-s);
  margin-top: 20px;
  margin-bottom: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-m);
  }
}
