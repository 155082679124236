.PaperTearImage,
.PaperTearImage-canvas {
  width: 100%;
  height: 100%;
}

.js-paperTearImage-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
