.HeroWithLink {
  color: var(--color-text);
  display: block;
  position: relative;
  text-decoration: none;
}

.HeroWithLink-contentBlock {
  background-color: var(--color-white);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.2);
  display: block;
  outline: none;
  position: relative;
  text-decoration: none;
  top: -10px;
  transition: box-shadow 200ms ease-out;
  z-index: 1;

  @media (--viewport-md) {
    bottom: 0;
    left: 20px;
    position: absolute;
    top: auto;
    transform: translateY(50%);
  }

  &:hover,
  &:focus {
    /* box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.3); */
  }
}
