.ButtonGray {
  align-items: center;
  background-image: linear-gradient(-180deg, var(--color-white) 0%, var(--color-gray-lightest) 100%);
  border-radius: 6px;
  border: 1px solid var(--color-gray-light);
  color: var(--color-text);
  display: flex;
  justify-content: center;
  min-height: 48px;
  padding: 10px 12px;
  text-decoration: none;
}

button.ButtonGray {
  width: 100%;
}

.ButtonGray svg {
  fill: var(--color-text);
  flex-shrink: 0;
}

.ButtonGray svg + .ButtonGray-label {
  margin-left: 8px;
}

.ButtonGray-label {
  font-family: var(--font-family-base);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  white-space: nowrap;
}
