.Page-pageHeader {
  margin-bottom: 20px;
  margin-top: 50px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 80px;
  }
}

.Page-hero {
  margin-bottom: 30px;

  @media (--viewport-md) {
    margin-bottom: 60px;
  }
}

.Page-content {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}

.Page-newsletter {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}

.Page-aboutUs {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}
