:root {
  --color-link-link: var(--color-red);
  --color-link-visited: var(--color-red);
  --color-link-hover: var(--color-blue);
  --color-link-focus: var(--color-blue);
}

.WpContent {
  & ul,
  & ol {
    margin-bottom: 1.5em;
    margin-top: 1.5em;

    & > li > *:first-child {
      margin-top: 0;
    }
  }

  & ul {
    list-style: none;
    padding-left: 20px;

    & > li {
      position: relative;
      &:not(:last-child) { margin-bottom: 0.5em; }
    }

    & > li::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: -1.4em;
      top: 0.6em;
      background-color: var(--color-text);
    }
  }

  & > p:first-child,
  & > ol:first-child,
  & > ul:first-child {
    margin-top: 0;
  }

  & > br:last-child,
  & > br:first-child {
    display: none;
  }

  & > p:last-child,
  & > ol:last-child,
  & > ul:last-child {
    margin-bottom: 0;
  }

  & a {
    border-bottom: 2px solid transparent;
    color: var(--color-link-link);
    text-decoration: none;
    transition-duration: 200ms;
    transition-property: border-bottom-color, color;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus {
      border-bottom: 2px solid var(--color-link-hover);
      color: var(--color-link-hover);
    }
  }

  & img,
  & blockquote,
  & table {
    display: none;
  }
}
