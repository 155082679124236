.Select-container {
  position: relative;
}

.Select-container svg {
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;

  @media (--viewport-md) {
    display: none;
  }
}

.Select {
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: currentColor;
  padding: 10px 30px 10px 10px;
  text-overflow: ellipsis;
  width: 180px;

  @media (--viewport-sm) {
    width: 200px;
  }

  @media (--viewport-md) {
    width: auto;
  }

  &:focus {
    outline: none;
  }
}

/* stylelint-disable no-descending-specificity */
.Select::-ms-expand { display: none; }
/* stylelint-enable no-descending-specificity */
