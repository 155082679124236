@import 'utilities/container';

.Single-pageHeader {
  margin-bottom: 20px;
  margin-top: 50px;

  @media (--viewport-md) {
    margin-bottom: 40px;
    margin-top: 80px;
  }
}

.Single-hero {
  margin-bottom: 60px;

  @media (--viewport-md) {
    @apply --container-base;

    max-width: var(--container-width-xxl);
  }
}

.Single-authorSmall {
  margin-bottom: 30px;
  border-bottom: solid var(--color-black) 2px;
  border-top: solid var(--color-black) 2px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 32px;

  @media (--viewport-md) {
    margin-bottom: 30px;
  }
}

.Single-date {
  margin-bottom: 20px;
}

.Single-content {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 100px;
  }
}

.Single-dottedLine,
.Single-share {
  margin-bottom: 30px;

  @media (--viewport-md) {
    margin-bottom: 40px;
  }
}

.Single-authorBig {
  margin-bottom: 50px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}

.Single-authorBig,
.Single-aboutUs,
.Single-related,
.Single-newsletter {
  margin-bottom: 40px;

  @media (--viewport-md) {
    margin-bottom: 80px;
  }
}
