:root {
  @custom-media --viewport-hor screen and (min-width: 76.875em); /* 1230px */
}

.DrawerMenu {
  padding: 80px 0 0;
  width: 100%;

  @media (--viewport-md) {
    /*
      ie11 doesn't want to play nice with min-height in Drawer-layout
      128px is height of site-footer
    */
    min-height: calc(100vh - 128px);
  }

  @media (--viewport-lg) {
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  @media (--viewport-hor) {
    max-width: 1230px;
    align-items: center;
  }
}

.DrawerMenu-layout {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media (--viewport-hor) {
    flex-wrap: nowrap;
    width: 100%;
  }
}

.DrawerMenu-chapterLink {
  mix-blend-mode: multiply;
}

.DrawerMenu-chapterLink--products {
  width: 300px;
  margin-left: auto;
  margin-top: 20px;

  @media (--viewport-sm) {
    width: 380px;
  }

  @media (--viewport-md) {
    margin-left: 20px;
    margin-top: 60px;
  }

  @media (--viewport-lg) {
    margin-left: 20px;
    width: 100%;
    min-width: 400px;
    max-width: 420px;
    margin-top: 80px;
  }

  @media (--viewport-hor) {
    width: 31%;
    flex-shrink: 0;
    min-width: 0;
    max-width: 100%;
  }
}

.DrawerMenu-chapterLink--recipes {
  width: 220px;
  margin-top: 20px;
  margin-left: 20px;

  @media (--viewport-sm) {
    margin-left: auto;
    margin-top: 0;
  }

  @media (--viewport-md) {
    margin-left: auto;
    width: 300px;
    margin-top: 160px;
  }

  @media (--viewport-lg) {
    width: 34%;
  }

  @media (--viewport-hor) {
    width: 25%;
    flex-shrink: 0;
    min-width: 0;
    max-width: 100%;
  }
}

.DrawerMenu-chapterLink--updates {
  width: 300px;
  margin-top: -5px;

  @media (--viewport-sm) {
    margin-top: 0;
  }

  @media (--viewport-md) {
    margin-top: -180px;
  }

  @media (--viewport-lg) {
    margin-top: -140px;
  }

  @media (--viewport-hor) {
    width: 22%;
    flex-shrink: 0;
    min-width: 0;
    max-width: 100%;
    margin-top: -40px;
  }
}

.DrawerMenu-chapterLink--about {
  width: 260px;
  margin-left: auto;

  @media (--viewport-hor) {
    width: 22%;
    flex-shrink: 0;
    margin-left: 0;
    margin-top: 200px;
  }
}

.DrawerMenu-decoration {
  position: relative;
}

.DrawerMenu-decoration--insetBasil {
  margin-left: auto;
  z-index: 2;
  margin-top: -80px;
  display: inline-block;

  @media (--viewport-sm) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (--viewport-md) {
    width: auto;
    position: absolute;
    right: 0;
    top: 200px;
    display: inline-block;
  }
}

.DrawerMenu-decoration--insetBasil .DrawerMenu-decoration-inset {
  width: 160px;
}

.DrawerMenu-decoration--shapeBoomerang {
  position: relative;
  width: 200px;
  transform: rotate(99deg);
  top: -40px;
  left: 110%;
  mix-blend-mode: multiply;

  @media (--viewport-sm) {
    margin-right: auto;
    left: 0;
    top: 0;
    transform: rotate(30deg);
  }

  @media (--viewport-md) {
    left: 200px;
  }

  @media (--viewport-hor) {
    position: absolute;
    left: 68%;
    top: 200px;
  }
}

.DrawerMenu-decoration--shapeBoomerang .DrawerMenu-decoration-shape {
  position: absolute;
  pointer-events: none;
}

.DrawerMenu-decoration--insetRedChili {
  width: 160px;
  margin-left: auto;
  position: relative;
  z-index: 1;
  margin-top: -80px;

  @media (--viewport-lg) {
    width: 200px;
    position: absolute;
    bottom: -80px;
    right: -40px;
  }
}

.DrawerMenu-decoration--tamarind {
  display: none;

  @media (--viewport-lg) {
    position: absolute;
    display: block;
    top: -120px;
    left: 27%;
    width: 220px;
    z-index: 1;
  }
}

.DrawerMenu-decoration--garlic {
  display: none;

  @media (--viewport-lg) {
    position: absolute;
    display: block;
    width: 180px;
    bottom: -100px;
    left: -60px;
  }
}
