.TermNav {
  border-bottom: 2px solid var(--color-black);
  border-top: 2px solid var(--color-black);
  font-family: var(--font-family-slab);
  padding-bottom: 10px;
  padding-top: 10px;

  @media (--viewport-md) {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.TermNav-layout {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.TermNav-label,
.TermNav-select {
  font-size: var(--font-size-m);
  text-transform: uppercase;

  @media (--viewport-md) {
    display: none;
  }
}

.TermNav-select {
  font-family: var(--font-family-slab);
  font-weight: var(--font-weight-medium);

  & option {
    text-transform: initial;
  }
}

.TermNav-list {
  display: none;

  @media (--viewport-md) {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.TermNav-link {
  color: var(--color-text);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: 1.3;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 200ms ease-in-out;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: var(--color-greenish-blue);
  }
}

.TermNav-link--active {
  border: 2px solid currentColor;
  border-width: 2px 0;
}
