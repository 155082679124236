#countrymap {
    background-image:url("https://svgsilh.com/svg_v2/67861.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    min-height: 50vh;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#countrymap .u-container-lg {
    max-height: 110px;
}

#countryinfo {
    padding: 30px 0 50px;
    text-align: center;
    background: white;
}

#countryinfo .country-contactinfo {
    padding: 4rem 0 0 0;
    position: relative;
    background: #fefefe;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}

#countryinfo .contact-p {
    font-size:18px;
}

#countryinfo .country-contactinfo.closed {
    margin-top: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

/* Select accordion */
#countrymap .Accordion-container {
    display: flex;
    padding-top: 30px;
    justify-content: center;
}

#countrymap .Accordion {
    max-width: 500px;
    position: relative;
&:before {
     content: "";
     background-image: url(https://www.fairtradeoriginal.nl/content/themes/kaliber-fair-trade-original/assets/images/tilde.webp);
     background-repeat: no-repeat;
     background-size: contain;
     width: 130%;
     padding-top: 58%;
     display: inline-block;
     position: absolute;
     z-index: -1;
     top: 0;
     left: 50%;
     transform: translateX(-50%) translateY(-50%) rotate(-6deg);
 }
}

#countrymap .Accordion-content  {
    max-height: 150px;
    overflow-y: auto;
}

#countrymap .Accordion-item {
    background-color: white;
    box-shadow: 0 12px 24px -10px rgba(0, 0, 0, .2);
}

#countrymap .Accordion-title {
    padding-top: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

#countrymap .Accordion-button {
    padding: 0 10px 10px 10px;
}

#countrymap .Accordion-contentLayout {
    padding: 0;
}

#countrymap .Accordion-contentLayout ul {
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: left;
}

#countrymap .Accordion-contentLayout ul li {
    padding: 2px 10px;
    cursor: pointer;
    transition: background-color .5s;
&:hover,
&.active {
     background-color: #e8e8e8;
 }
}