:root {
  @custom-media --two-columns screen and (min-width: 36em); /* 400px */
  @custom-media --three-columns screen and (min-width: 64em); /* 1024px */

  --grid-gutter-horizontal: 15px;
  --grid-gutter-vertical: 16px;
  --grid-gutter-horizontal-three-columns: 30px;
  --grid-gutter-vertical-three-columns: 32px;
  --item-width-two-column: calc(50% - var(--grid-gutter-horizontal) * (1 / 2));
  --item-width-three-column: calc((100% / 3) - var(--grid-gutter-horizontal-three-columns) * (2 / 3));
}

.Grid {
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    @media (--two-columns) {
      display: grid;
      grid-template-columns: var(--item-width-two-column) var(--item-width-two-column);
      grid-gap: var(--grid-gutter-horizontal);
    }

    @media (--three-columns) {
      grid-template-columns: var(--item-width-three-column) var(--item-width-three-column) var(--item-width-three-column);
      grid-gap: var(--grid-gutter-horizontal-three-columns);
    }
  }
}

.Grid--hideLast .Grid-item:last-child {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.Grid-item {
  width: 100%;
  margin-bottom: var(--grid-gutter-vertical);

  & > * {
    height: 100%;
  }

  @media (--two-columns) {
    width: var(--item-width-two-column);

    &:nth-child(odd) {
      margin-right: var(--grid-gutter-horizontal);
    }
  }

  @media (--three-columns) {
    width: var(--item-width-three-column);
    margin-bottom: var(--grid-gutter-vertical-three-columns);
    margin-right: var(--grid-gutter-horizontal-three-columns) !important;

    &:nth-child(3n) {
      margin-right: 0 !important;
    }
  }

  @supports (display: grid) {
    margin-right: 0 !important;
    width: 100% !important;

    @media (--two-columns) {
      margin-bottom: calc(var(--grid-gutter-vertical) - var(--grid-gutter-horizontal));
    }

    @media (--two-columns) {
      margin-bottom: calc(var(--grid-gutter-vertical-three-columns) - var(--grid-gutter-horizontal-three-columns));
    }
  }
}
