@media print {
    .SiteLayout {
        padding: 3.5rem;
    }
    .SingleRecipe .Title {
        font-family: inherit;
        text-transform: none;
        font-size: 24px;
        font-weight: bold;
    }
    .SingleRecipe .Title > div {
        display: inline-block !important;
        margin: 0 3px;
    }
    .SingleRecipe .Title-char {
        transform: none !important;
        opacity: 1 !important;
    }
    .SingleRecipe .SingleRecipe-description {
        display: none;
    }

    .SingleRecipe .js-star-rating {
        display: none;
    }

    .SingleRecipe .Ingredients-link {
        color: black;
    }
    .SingleRecipe .Preparation-listItem:after,
    .SingleRecipe .Ingredients-linkListItem:after{
        background-color: black;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .footer-store-grid-row {
        justify-content: left;
    }
    .footer-store-grid-item {
        flex: 0 0 60px;
        max-width: 60px;
        padding: 0.825rem;
    }

    .SiteHeader {
        position: relative;
        background-color: transparent;
        transform: none;
    }

    .SingleRecipe-pageHeader {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .SiteLayout-main {
        padding-top: 0;
    }

    .SiteHeader-logo {
        transform: none;
    }
    .SiteHeader-logoImage {
        width: 100px;
        height: auto;
        filter: grayscale(1);
        margin-top: 10px;
    }
    .Ingredients-linkListItem,
    .Ingredients-listItem {
        margin-bottom: 0;
    }
    .Ingredients-title,
    .Ingredients-subtitle,
    .Preparation-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2.4rem;
    }
    .Ingredients-list {
        margin-bottom: 0;
    }
    .Preparation-listItem {
        margin-bottom: 0;
    }
    .Ingredients-list {
        column-count: 3;
        column-gap: 40px;
    }
    .Ingredients-main {
        margin-bottom: 2rem;
    }
    .SingleRecipe-recipe {
        margin-bottom: 1rem;
    }
    .footer-store-container {
        border-top: 0;
        padding: 0;
        display: none;
    }

    .footer-store-container .u-container-lg,
    .footer-store-container .Page-footer-store {
        transform: none !important;
    }
}

@page { size: auto;  margin: 0mm; }