.Shape {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}

.Shape-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.Shape-defPattern {
  opacity: 0.6;
}

.Shape-def--overlay {
  opacity: 0.2;
}

@supports (mix-blend-mode: overlay) {
  .Shape-def--overlay {
    mix-blend-mode: overlay;
    fill-opacity: 0.34;
    opacity: 1;
  }
}

.Shape-svg:not(.Shape--overlay):not(.Shape--overImage) {
  opacity: 0.8;
}

.Shape--overImage .Shape-pattern {
  opacity: 0.4;
}

@supports (mix-blend-mode: multiply) {
  .Shape-svg:not(.Shape--overlay):not(.Shape--overImage) {
    mix-blend-mode: multiply;
    opacity: 1;
  }
}
